import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NewLaunchComponent } from './components/new-launch/new-launch.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { StoreSiteComponent } from './components/store-site/store-site.component';
import { StoreRouterComponent } from './store-router/store-router.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: ':formattedAddress',
    component: StoreSiteComponent
  },
  {
    path:'single_store/landing_page/:locationId',
    component: StoreRouterComponent
  },
  {
    path:'find',
    children:[
      {
        path:'**',
        component: HomeComponent
      }
    ]
  },
  {
    path:'store/not-found/:formattedAddress',
    component:PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
