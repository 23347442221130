import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiService } from "src/app/api.service";
import { AppComponent } from "src/app/app.component";
import { SharedInfoService } from "src/app/sharedInfo.service";
declare var $: any;

@Component({
  selector: "app-section",
  templateUrl: "./section.component.html",
  styleUrls: ["./section.component.scss"],
})
export class SectionComponent implements OnInit, AfterViewInit {
  @Input() sectionInput: any;
  mainSection: any;
  YTShown: boolean = false;
  banners: any = {};
  bannarVideos: any = {};
  offerBannerDesk: any;
  reviews: any[] = [];
  bannerSection: any[] = [];
  isAutoplay: boolean = true;
  private sliderInitialized = false;

  constructor(
    private sharedInfoService: SharedInfoService,
    private breakpointObserver: BreakpointObserver,
    private santizer: DomSanitizer,
    public app: AppComponent,
    private apiService: ApiService
  ) {
 
  }

  ngOnInit(): void {
    this.apiService.reviewsObservable.subscribe((data) => {
      this.reviews = data;
      this.slickReviews();
    });
    this.sharedInfoService.currentSocialMedia.subscribe((data: any) => {
      this.mainSection = data;
    });

    let baseURL = "";

    if (
      window.location.hostname === "127.0.0.1" ||
      window.location.hostname === ""
    ) {
      baseURL = "https://dell-dev.hashconnect.in";
    }

    $.ajax({
      url: baseURL + "/api/tenant/content/config?tenantCode=STORE-LOCATOR",
      type: "GET",
      dataType: "json",
      secure: true,
      headers: {
        "Content-Type": "application/json",
      },
      success: (response: any) => {
        console.log(response);
        this.banners = [];
        let content = JSON.parse(response.content.content);
        if (content.footer) this.apiService.footer.next(content.footer);
        if (content.products) this.apiService.products.next(content.products);
        if (content.faq) this.apiService.faq.next(content.faq);

        if (content.storeLocator)  this.banners = content.storeLocator;
        if(this.banners.offerBannerDesk){
          this.bannerSection = this.banners.offerBannerDesk.sort(
            (a: any, b: any) => a.order - b.order
          );
          console.log("bannerSection => ", this.bannerSection)
        }
      
        this.bannarVideos = this.banners.videos
          .filter((t: any) => t.isDisplay === true)
          .sort((a: any, b: any) => a.order - b.order);
          this.videosSlider();
        setTimeout(() => {
          this.reviewBanner();
        }, 500);
      },
    });
  }

  videosSlider() {
    $(document).ready( () => {
      setTimeout(() => {
        var offerSlider = $(".videoSlider");
        if (offerSlider.hasClass("slick-initialized")) {
          $(".videoSlider").slick("unslick");
        }
        if (offerSlider != null) {
          offerSlider.slick({
            centerMode: false,
            centerPadding: "0px",
            dots: false,
            arrows: false,
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            nextArrow:
              '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" aria-hidden="true"><path d="M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56823 3.03617 8.69181 1.52242 12.3463C0.00866569 16.0008 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9991 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C39.9944 14.6974 37.8855 9.61356 34.136 5.86405C30.3864 2.11453 25.3026 0.00559965 20 0ZM28.7808 21.0885L22.6269 27.2423C22.3382 27.531 21.9467 27.6932 21.5385 27.6932C21.1302 27.6932 20.7387 27.531 20.45 27.2423C20.1613 26.9536 19.9991 26.5621 19.9991 26.1538C19.9991 25.7456 20.1613 25.3541 20.45 25.0654L23.9788 21.5385H12.3077C11.8997 21.5385 11.5084 21.3764 11.2198 21.0879C10.9313 20.7993 10.7692 20.408 10.7692 20C10.7692 19.592 10.9313 19.2007 11.2198 18.9121C11.5084 18.6236 11.8997 18.4615 12.3077 18.4615H23.9788L20.45 14.9346C20.1613 14.6459 19.9991 14.2544 19.9991 13.8462C19.9991 13.4379 20.1613 13.0464 20.45 12.7577C20.7387 12.469 21.1302 12.3068 21.5385 12.3068C21.9467 12.3068 22.3382 12.469 22.6269 12.7577L28.7808 18.9115C28.9238 19.0544 29.0373 19.2241 29.1147 19.4109C29.1921 19.5976 29.232 19.7978 29.232 20C29.232 20.2022 29.1921 20.4024 29.1147 20.5891C29.0373 20.7759 28.9238 20.9456 28.7808 21.0885Z" fill="black"/></svg></button>',
            prevArrow:
              '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" aria-hidden="true"><path d="M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56823 3.03617 8.69181 1.52242 12.3463C0.00866569 16.0008 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9991 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C39.9944 14.6974 37.8855 9.61356 34.136 5.86405C30.3864 2.11453 25.3026 0.00559965 20 0ZM27.6923 21.5385H16.0212L19.55 25.0654C19.6929 25.2083 19.8063 25.378 19.8837 25.5648C19.961 25.7515 20.0009 25.9517 20.0009 26.1538C20.0009 26.356 19.961 26.5562 19.8837 26.7429C19.8063 26.9297 19.6929 27.0994 19.55 27.2423C19.4071 27.3852 19.2374 27.4986 19.0506 27.576C18.8639 27.6533 18.6637 27.6932 18.4615 27.6932C18.2594 27.6932 18.0592 27.6533 17.8725 27.576C17.6857 27.4986 17.516 27.3852 17.3731 27.2423L11.2192 21.0885C11.0762 20.9456 10.9627 20.7759 10.8853 20.5891C10.8079 20.4024 10.768 20.2022 10.768 20C10.768 19.7978 10.8079 19.5976 10.8853 19.4109C10.9627 19.2241 11.0762 19.0544 11.2192 18.9115L17.3731 12.7577C17.6618 12.469 18.0533 12.3068 18.4615 12.3068C18.8698 12.3068 19.2613 12.469 19.55 12.7577C19.8387 13.0464 20.0009 13.4379 20.0009 13.8462C20.0009 14.2544 19.8387 14.6459 19.55 14.9346L16.0212 18.4615H27.6923C28.1003 18.4615 28.4917 18.6236 28.7802 18.9121C29.0687 19.2007 29.2308 19.592 29.2308 20C29.2308 20.408 29.0687 20.7993 28.7802 21.0879C28.4917 21.3764 28.1003 21.5385 27.6923 21.5385Z" fill="black"/></svg></button>',
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 1250,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 969,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  centerMode: false,
                  centerPadding: "0px",
                  slidesToShow: 1,
                },
              },
            ],
          });
        }
        this.getObservable();
      }, 1000);
    });
  }

  getObservable() {
    this.breakpointObserver
      .observe(["(min-width: 1025px) and (max-width: 1200px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          // this.app.record = 3;
        } else {
          this.iframeDesign();
          // if(window.innerWidth < 1500){
          //   this.app.record = 4;
          // }
         
        }
      });
  }

  iframeDesign() {
    let slick = $(".videoSlider");
    let slickHeight = slick.height();
    let slickWidth = slick.width();
    // let newWidth = slickWidth * 1;
    // if (window.innerWidth < 768) {
    //   $('.frameVideo').css('height', slickHeight);
    //   $('.frameVideo').css('width', "100%");
    //   $('.offer-sec .col-md-8').css('background-color', 'transparent');
    // } else {
    //   $('.frameVideo').css('height', slickHeight - 20);
    //   $('.frameVideo').css('width', newWidth - 60);
    // }
  }

  toggleAutoplay() {
    this.isAutoplay = !this.isAutoplay;
  }

  reviewBanner() {
    $(document).ready(() => {
      $(".reviewSlider").slick({
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              dots: true,
            },
          },
        ],
      });

      // setTimeout(() => {
      //   $(".reviewSlider-image").css("height", $(".reviewSlider").height());
      //   $(".reviewSlider-image").css("width", $(".reviewSlider").width());
      // }, 1000);
    });
  }

  slickReviews() {
    setTimeout(() => {
    let  $reviewSlider = $(".rsWrap").slick({
        infinite: true,
        autoplay: this.isAutoplay,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: "false",
      });
      $('#toggleAutoplay').on('click', (e : any) =>{
        this.isAutoplay = !this.isAutoplay;
        $reviewSlider.slick('slickSetOption', 'autoplay', this.isAutoplay, true);
        $(e.target).find('i').toggleClass('fa-play fa-pause');
    });
    // Set the initial icon
    $('#toggleAutoplay').find('i').addClass('fa-pause');
    }, 0);
  }

  ngAfterViewInit(): void {
    $(".grList").slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,

            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,

            dots: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

            dots: true,
          },
        },
      ],
    });

    this.breakpointObserver
      .observe(["(max-width: 701px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          $(".mobielHomeSlider").slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ],
          });
          $(".mobileSlider").slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ],
          });
        }
      });
  }

  getYTUrl1() {
    if (!this.YTShown) {
      this.YTShown = true;
      return this.santizer.bypassSecurityTrustResourceUrl(
        "https://youtube.com/embed/6iZW7X86VcY?rel=0&origin=" +
          this.app.YTOriginUrl
      );
    }
    return false;
  }

  getYTUrl() {
    if (!this.YTShown) {
      this.YTShown = true;
      //change from youtube link to cloud video link
      return this.santizer.bypassSecurityTrustResourceUrl(
        "https://s3.ap-south-1.amazonaws.com/dellretailstores.in/Dell+Product+Videos/DellXPS_Millennio-SKY_Alt_27_16x9_Web_v2_updated_1920x1080.mp4"
      );
    }
    return false;
  }
}
