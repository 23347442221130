export class Lead {

    private email: string;
    private mobileNo: string;
    private name: string;
    private pincode: string;
    private sourceName: number;
    private storeId: number;
    private consent: boolean;
    // private displayName: string;
    // private maskingNumber: number;
    // private gmapLink: string;



    constructor(_name: string, _email: string, _mobileNo: string, _pincode: string, _sourceName: number, _storeId: number, _consent: boolean) {
        this.name = _name;
        this.email = _email;
        this.mobileNo = _mobileNo;
        this.pincode = _pincode;
        this.sourceName = _sourceName;
        this.storeId = _storeId;
        this.consent = _consent;
  
    }

}