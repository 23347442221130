import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  storeFormattedAddress: any;

  constructor(private activatedRoute: ActivatedRoute,private router: Router) { 
    this.activatedRoute.params.subscribe(data => {
      this.storeFormattedAddress = data.formattedAddress
    })
  }

  ngOnInit(): void {
  }

  goToHome(){
    this.router.navigate(['']);
  }
}
