import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SharedInfoService {

    socialMedia = new BehaviorSubject({});
    currentSocialMedia = this.socialMedia.asObservable();

    storeList: EventEmitter<any> = new EventEmitter<any>();

    page = new BehaviorSubject(1);
    currentPage = this.page.asObservable();

}