<div class="googleMapWrapper"
id="scroll"
    [ngStyle]="isShown?{'visibility':'visible','height':'auto'}:{'visibility':'hidden','height':'0'}">
    <div>
        <google-map #gMap [center]="center?center:{lat:12,lng:75}" [zoom]="5" [width]="'100%'" 
        [mapTypeId]="mapTypeId"
        [options]="styledMapType"
        >
            <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position"
            [options]="marker.draggable"
            (mapClick)="openInfo(markerElem, marker.info)"
            [icon]="marker.icon"
            >
            </map-marker>
            <map-info-window>
                <div class="infoWin" [innerHTML]="infoContent"></div>
            </map-info-window>
        </google-map>
    </div>
</div>
<section class="storeListingWrapper" *ngIf="storeInfoDetail.length > 0; else nodata">
    <div class="container" [style.max-width]="app.maxWidth + 'px'">
        <div class="slHead">
            <h1 class="pageHead" tabindex="0">Store Near You in <strong> {{app.currentCity}}</strong></h1>
            <div class="slHeadRight">
                <p *ngIf="distanceForStores"><strong>Found {{distanceForStores.store_within_radius}} store</strong> {{distanceForStores.total_store - distanceForStores.store_within_radius}} stores beyond {{app.distanceForTotalStore}}Km</p>
                <a (click)="toggleShow()" id="viewOnMapBtn" tabindex="0"  class="yellowBtn">
                    <span role="button" >
                        View On Map
                    </span>
                   </a>
            </div>
        </div>

        <div class="slWrapper" id="slWrapper" [hidden]="isShown">
            <ul class="slListing bigarrow">
                <li class="listingCard" *ngFor="let item of storeInfoDetail; let i=index;"   [style.width]="app.cardWidth" >
                    <strong class="storeDistance">
                        <em [ngClass]="i==0 ? 'sdIcon': 'sdIcon flag'"></em> {{ i == 0 ? "Nearest Store - " : " " }}
                        <span> {{ i == 0 ? " " : "Distance - " }} {{ item.distance | number : '.2-2' }} Km</span>
                        <span class="sharewrap" (click)="sharemobiel(item.displayName ,item.formattedAddress)">Share</span>
                    </strong>
                    <div class="lcHead">
                        <h2>
                            <a [routerLink]="[item.formattedAddress]" (click)="app.getDataLayervalues(item, 'Get_in_touch_store')" queryParamsHandling="merge" > 
                                 {{item.displayName | textSpliteByHyphenPipe:'-':0}}
                                <small>{{item.displayName | textSpliteByHyphenPipe:'-': 1 }}</small>
                                <span class="sr-only"> {{item.displayName | textSpliteByHyphenPipe:'-':0}} - {{item.displayName | textSpliteByHyphenPipe:'-': 1 }}</span>
                               
                            </a>
                        </h2>

                         <span *ngIf="item.avg_rating != null" class="starRating">{{item.avg_rating}}</span>
                    </div>
                    <div class="storeAdress">
                        <p>
                            {{item.address}}
                        </p>
                    </div>
                    <ol class="sdListItems">
                        <li>
                            <abbr>
                                <span>Open From</span>
                                <em>{{utils.getTodayStoreTimings(item.timings)}}</em>
                            </abbr>
                        </li>
                        <li>
                            <a href="tel:{{item.phoneNumber}}" class="call">
                                <span class="sr-only">Contact {{item.displayName | textSpliteByHyphenPipe:'-':1}} on</span>
                                {{item.phoneNumber}}</a>
                        </li>
                        <li>
                            <a [attr.href]="item.gmapLink" target="_blank" class="getDirection">
                  
                                Get Directions
                                <span class="sr-only"> for {{item.displayName | textSpliteByHyphenPipe:'-':1}}</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="[item.formattedAddress]" queryParamsHandling="merge" class="websiteLink" (click)="app.getDataLayervalues(item, 'Get_in_touch_store')">Store Website
                                <span class="sr-only"> for {{item.displayName | textSpliteByHyphenPipe:'-':1}}</span>
                            </a>
                        </li>
                    </ol>
                    <a href="tel:{{item.phoneNumber}}" (click)="app.getDataLayervalues(item, 'Get_in_touch_store')" style="display: flex;justify-content: center;"
                        class="gradientBtn">Get In Touch <span class="sr-only"> for {{item.displayName | textSpliteByHyphenPipe:'-':1}}</span><span class="mobileNiHidemo"> - {{item.phoneNumber}}</span></a>
                    <button class="call-back" (click)="triggerOpenPopup(item.id);app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:n1qrllz&fmt=3')">
                        Request Call Back
                        <span class="sr-only"> for {{item.displayName | textSpliteByHyphenPipe:'-':1}}</span>
                    </button>
                </li>
                <li class="listingCard" id="lastPage" *ngIf="result">
                    <div>
                        <button class="lastPage" (click)="loadMoreStoresMob()">Load More</button>
                    </div>
                </li>
            </ul>
            <div class="loadMoreBtnWrap loadmoreListing">
                <button class="loadMoreBtn" id="loadMore" tabIndex="0"  role="button" (click)="loadMoreStores()" tabindex="0" [hidden]="loadMore"
                    [innerHTML]="loadMoreText">
                
                </button>
            </div>
        </div>

    </div>
</section>
<ng-template #nodata>
    <h1 class="text-center pt-4">No content :(</h1>
</ng-template>

