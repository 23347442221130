<div class="container">
    <div class="poBox">
        <h2 class="boldLIght"><strong>What's</strong> New</h2>
        <div>
            <div class="videoSlider">
                <div class="newProduct bigarrow" *ngFor="let youVideo of bannarVideos">
                    <div class="poInnerBox">
                        <div class="poCardwrap__Fullwidth">
                            <iframe class="frameVideo" [src]="youVideo.url | safe" allowfullscreen
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation"
                                alt="dell product video" [width]="app.iframeHieght" [height]="app.iframeHieght/ 2"></iframe>
                        </div>
                    </div>
                    <div class="swiper-scrollbar"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="poBox pobox30" *ngIf="bannerSection.length > 0">
        <h2 class="boldLIght"><strong>Offers</strong></h2>

        <div class="newProduct bigarrow">
            <div class="poInnerBox">
                <div class="poCardwrap offerBanner">
                    <div class="reviewSlider">
                        <div class="reviewItem offerBanner" *ngFor="let offer of bannerSection; let i =index" [attr.aria-live]="'slide ' + (i + 1) + ' of ' + bannerSection.length" [attr.aria-label]="'slide ' + (i + 1) + ' of ' + bannerSection.length">
                            <a [href]="offer.redirectUrl" queryParamsHandling="merge">
                                <img class="reviewSlider-image" src="{{offer.url}}" alt="offer-{{i + 1}}" />
                                <span class="sr-only">{{offer.redirectUrl}}</span>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="swiper-scrollbar"></div>
        </div>

    </div>
    <div class="poBox pobox30" *ngIf="reviews.length > 0">

        <h2 class="boldLIght"><strong>Our </strong>Store</h2>
        <div class="newProduct bigarrow">
            <div class="poInnerBox">
                <div class="poCardwrap wrapReviewSlider">
                    <div>
                        <h3>Dell Stores Rating</h3>
                        <p>Average rating of all our stores</p>
                        <div class="starRatingwrap">
                            <img src="./assets/img/rednewstar.svg" alt=""> <small
                                style="margin-top: 2px;">{{reviews[0].averate_rating}}</small>
                        </div>
                    </div>
                    <div class="reviewSliderNew">
                        <div class="d-flex justify-content-between">
                            <h3>Customer Speak</h3>
                            <div class="d-flex">
                                <button id="toggleAutoplay" class="toggle-button border-none">
                                    <i [class.fa-play]="!isAutoplay" [class.fa-pause]="isAutoplay" class="fas"></i>
                                     <span class="sr-only">Auto scroll Play/Pause</span>
                                  </button>
                            </div>
                       
                        </div>
                    
                        <div class="rsWrap">
                            <div *ngFor="let review of reviews; let i = index" class="rsSlide" [attr.aria-live]="'slide ' + (i + 1) + ' of ' + reviews.length" [attr.aria-label]="'slide ' + (i + 1) + ' of ' + reviews.length">
                                <div class="rsTop">
                                    <i class="profilePhoto">
                                        <img src="assets/img/avatar.png" alt="profile-avatar">
                                    </i>
                                    <div class="rsDetail">
                                        <h4>{{review.display_Name}}</h4>
                                        <h5>{{review.crt_Dt | date : 'mediumDate'}}</h5>
                                    </div>
                                    <abbr class="cstReview">
                                        <img src="assets/img/singleStarred.svg" alt="rating-icon"> {{review.star_Rating}}
                                    </abbr>
                                </div>
                                <div class="custrevText">
                                    <p>{{review.comment}}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div class="swiper-scrollbar"></div>
        </div>
    </div>
</div>