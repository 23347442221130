<footer class="dell-footer">
    <ul class="footer-menu">
        <li *ngFor="let item of footer?.menus">
            <a [href]="item.value" target="_blank">{{item.name}}</a>
        </li>
    </ul>
    <div class="footer-logo">
        <a [href]="footer?.logo?.home.link" target="_blank">
        <img [src]="footer?.logo?.home.img" style="height: 23px;" alt="">
         </a>
    </div>
</footer>