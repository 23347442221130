<div class="faqWrap" style="margin-top: 15px;">
    <h2 class="boldLIght towLineHead">
        <strong><span>FAQs</span></strong>
    </h2>
    <ul class="faqAccordion">
        <li class="content" *ngFor="let faq of faqs; let i = index">
            <h3 (click)="toggleAnswer(i)">
                {{faq.title}}
                <em></em>
            </h3>
            <div class="answer" [hidden]="!isExpanded[i]">
                <p>
                    <span [innerHTML]="faq.description">
                    </span>
                </p>
            </div>
        </li>

    </ul>
</div>