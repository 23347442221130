import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-store-router',
  templateUrl: './store-router.component.html',
  styleUrls: ['./store-router.component.scss']
})
export class StoreRouterComponent implements OnInit {

  storeFormattedAddress: any;
  locationId: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router
    , private api: ApiService, private app: AppComponent) {
    this.activatedRoute.params.subscribe(data => {
      //this.storeFormattedAddress = data.formattedAddress
      if (data && data.locationId) {
        this.locationId = data.locationId;

        this.api.getStoreByLocationId(this.locationId, this.app.tenantId).subscribe((store) => {
          router.navigate([store.formattedAddress]);
        });

      }
    })
  }

  ngOnInit(): void {

  }

}
