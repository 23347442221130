import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit, AfterViewInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/utilities.service';
import { ApiService } from 'src/app/api.service';
declare var $: any;

@Component({
  selector: 'app-store-gallery',
  templateUrl: './store-gallery.component.html',
  styleUrls: ['./store-gallery.component.scss']
})
export class StoreGalleryComponent implements OnInit, AfterViewInit {
  
  @Output() requestCallBackWindow: EventEmitter<any> = new EventEmitter<any>();
  window: any;
  @Input() storDetails: any;
  storeSite : any = [];
  slickInterval: any ;
  constructor(public utils: UtilitiesService,
    private breakpointObserver: BreakpointObserver,
    public app: AppComponent,
    private apiService: ApiService) {  
     
     }

  ngAfterViewInit(): void {
    if(this.storDetails &&  this.storDetails.partnerImages &&  this.storDetails.partnerImages.length > 0){
      this.storDetails.partnerImages.sort((a:any, b:any) => {
        const typeA = a.partnerImageType.id;
        const typeB = b.partnerImageType.id;
        
        if (typeA < typeB) {
            return -1;
        }
        if (typeA > typeB) {
            return 1;
        }
        return 0;
    });
    }
   
    const shareButton = document.querySelector('.share-button');
const shareDialog = document.querySelector('.share-dialog');
const closeButton = document.querySelector('.close-button');

    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.storeImages('.storePhotosMobile');
      }
    });
  }


  ngOnInit() {
    this.getSiteImages();
    if (this.storDetails) {
      // console.log("STORE DETAILS >>>" + JSON.stringify(this.storDetails));
    }
     this.storeImages('.pgCoverImage');   
  }

 
  ngOnChanges(changes: SimpleChanges) {
    let storDetails = changes.storDetails.currentValue;
    if(storDetails &&  storDetails.partnerImages &&  storDetails.partnerImages.length > 0){
      this.storDetails.partnerImages.sort((a:any, b:any) => {
        const typeA = a.partnerImageType.id;
        const typeB = b.partnerImageType.id;
        
        if (typeA < typeB) {
            return -1;
        }
        if (typeA > typeB) {
            return 1;
        }
        return 0;
    });
    }
  }

  
  sharemobiel() {
    if (navigator.share) { 
     navigator.share({
        title: 'Dell Exclusive Store',
        url: this.app.YTOriginUrl + '/' + this.storDetails.formattedAddress
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
      } else {
         // shareDialog.classList.add('is-open');
      }
  };

  toggleImage(ev: Event, index: number) {
   $('.pgCoverImage').slick('slickGoTo', index );
  }

  storeImages(slider: string){
    $(document).ready(()=>{
      $(slider).slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed : this.slickInterval,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              infinite: true,
              autoplay: true,
              autoplaySpeed : this.slickInterval,
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 700,
            settings: {
              dots: false,
              infinite: true,
              autoplay: true,
              autoplaySpeed : this.slickInterval,
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              infinite: true,
              autoplay: true,
              autoplaySpeed : this.slickInterval,
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          }
        ]
      });
    })
  
  }

  getSiteImages() {
    this.apiService.getTenantConfig("STORE-LOCATOR").subscribe(
      (data) => {
         let content = JSON.parse(data.content.content)   
         this.storeSite = content.storeLocator;
         this.slickInterval = this.storeSite.siteImages.interval;      
      },
      (err:any)=>{
        console.log(err)
      })  
  }
  
  requestCallBackClick(){
    this.requestCallBackWindow.emit({ showRequestCall: true });

  }
}

