import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Lead } from './components/models/lead';
import { RequestLead } from './components/models/requestLead';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  footer = new BehaviorSubject<any>(null);
  footerObserable = this.footer.asObservable();

  faq = new BehaviorSubject<any>(null);
  faqObserable = this.faq.asObservable();

  products = new BehaviorSubject<any>(null);
  productsObserable = this.products.asObservable();

  heaerAndFooter = new BehaviorSubject<boolean>(true);
  hfSub = this.heaerAndFooter.asObservable();
  reviews = new BehaviorSubject<any>([]);
  reviewsObservable = this.reviews.asObservable();
  constructor(private httpClient: HttpClient) { }

  getTenantContent(tenantId: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/tenant/content`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": tenantId
      })
    });
  }

  getStoreDetails(tenantId: string, lat: number, lang: number, distance: number, record: number, type: string, page: number): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/store/radius?type=${type}&records=${record}&distance=${distance}&lat=${lat}&lng=${lang}&page=${page}`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": tenantId
      })
    })
  }

  getStoreDetailsByPlaceId(tenantId: string, placeId: string, distance: number, record: number, type: string, page: number): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/store/radius?type=${type}&records=${record}&distance=${distance}&placeId=${placeId}&page=${page}`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": tenantId
      })
    })
  }

  getformattedAddress(formattedAddress: string, tenantId: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/store/page/${formattedAddress}`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": tenantId
      })
    })
  }

  getStoreByLocationId(locationId: string, tenantId: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/location/${locationId}?type=s`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": tenantId
      })
    })
  }

  autoSearch(place: string, tenantId: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/auto-search/${place}`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": tenantId
      })
    })
  }

  getLocationByIp(): Observable<any> {
    const httpParam = new HttpParams().set('key', 'N7pS0QyVGJ09X0h');
    return this.httpClient.get<any>('https://pro.ip-api.com/json', {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      params: httpParam
    })
  }

  // res: any;
  // getCityByLatLng(lat: number, lng: number): Observable<any> {
  //   return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAQ2BO4jvLKQFKDoSnYahJIL9iiF1-NBiw&components=postal_code:&sensor=true&latlng=${lat},${lng}`)
  // }

  res: any;
  getCityByLatLng(lat: number, lng: number): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/geocode?lat=${lat}&lng=${lng}`);
  }

  saveLead(lead: Lead, tenantId: string): Observable<any> {
    const body = JSON.stringify(lead);
    return this.httpClient.post(`${environment.baseUrl}api/ui/landing-page/`+window.location.search, body,
      {
        headers: new HttpHeaders({
          "X_TENANT_ID": tenantId,
          'content-type': 'application/json'
        })
      })
  }

  getStoreListWithInKm(type: string, lat: number, lang: number, distance: number): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}api/ui/storeCount/${type}?distance=${distance}&lat=${lat}&lng=${lang}`,
    {
      headers: new HttpHeaders({
        "X_TENANT_ID": "1",
        'content-type': 'application/json'
      })
    })
  }

   storeLocatorPageLead(lead :any, sourceName:string, tenantId : any) : Observable<any>{
    const body = JSON.stringify(lead);
    const browserParams = window.location.search.length===0?"?sourceName="+sourceName:window.location.search+"&sourceName="+sourceName;
    return this.httpClient.post(`${environment.baseUrl}api/ui/store-locator-page/${browserParams}`,body,  {
      headers: new HttpHeaders({
        "X_TENANT_ID": tenantId,
        'content-type': 'application/json'
      })
    });
   }

   getlandingPageConfig(){
    return this.httpClient.get(`${environment.baseUrl}api/ui/landing-page/config`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": "1",
        'content-type':'application/json'
      })
    });       
   }

   getcategoryMaster(){
    return this.httpClient.get(`${environment.baseUrl}api/ui/category-master`,{
      headers: new HttpHeaders({
        "X_TENANT_ID": "1",
        'content-type':'application/json'
      })
    });
   }

   getReviews(lat : any, lng : any){
    return this.httpClient.get(`${environment.baseUrl}api/ui/reviews?lat=${lat}&lng=${lng}&type=s&radius=1000`, {
      headers: new HttpHeaders({
        "X_TENANT_ID": "1",
        'content-type':'application/json'
      })
    });       
   }

   getTenantConfig(tenantCode: string):Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/api/tenant/content/config?tenantCode=${tenantCode}`, 
    {
      responseType: 'json',
      headers: new HttpHeaders({
        "X_TENANT_ID": "1",
        'content-type':'application/json'
      })
    });
  }

  saveRequestCallBackLead(requestLead: RequestLead, tenantId: string, sourceName:string): Observable<any> {
    const body = JSON.stringify(requestLead);
    const browserParams = window.location.search.length===0?"?sourceName="+sourceName:window.location.search+"&sourceName="+sourceName;
    return this.httpClient.post(`${environment.baseUrl}api/ui/callback/${browserParams}`, body,
      {
        headers: new HttpHeaders({
          "X_TENANT_ID": tenantId,
          'content-type': 'application/json'
        })
      })
  }

  getProductInterest(){
    return this.httpClient.get(`${environment.baseUrl}api/ui/select-product`,{
      headers: new HttpHeaders({
        "X_TENANT_ID": "1",
        'content-type':'application/json'
      })
    });
   }

}
