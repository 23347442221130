import { AfterViewInit, Component, OnInit , OnDestroy, ViewChild, Renderer2, HostBinding, ElementRef, Inject} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/utilities.service';
import { SharedInfoService } from '../../sharedInfo.service';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RequestLead } from '../models/requestLead';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
// import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('popupAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'scale(0.8)' })),
      ]),
    ])
  ],
})
export class HomeComponent implements AfterViewInit , OnInit, OnDestroy{
  @ViewChild('requestCallBack') requestCallBack: any;
  public storeInfo: any;
  mainContent: any;
  page: any;
  show: boolean = false;
  showPopup: boolean = false;
  enquiryForm!: FormGroup;
  requestCallBackForm!: FormGroup;
  description: string = "";
  storeId: any;
  productInterest: any;
  formattedDate: any;
  minDate = new Date();
  consentText: any;

   script1!: HTMLScriptElement;
   script!: HTMLScriptElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  
    private sharedInfoService: SharedInfoService, private sanitizer: DomSanitizer,
    private api: ApiService,
    public app: AppComponent,
    public util: UtilitiesService,
    private meta: Meta,
    private title : Title,
    private fb : FormBuilder,
    private modalService : NgbModal,
    private router: Router,
    public dialog: MatDialog,
    private renderer: Renderer2) {

    this.sharedInfoService.storeList.subscribe(data => {
      this.sharedInfoService.currentPage.subscribe(page => {
        this.page = page;
        if (this.page == 1) {
          this.storeInfo = data;
        } else {
          this.storeInfo = [...this.storeInfo, ...data];
        }
      });

    });
    this.minDate.setDate(this.minDate.getDate());
  }
 ngOnInit(){
  
   this.api.heaerAndFooter.next(true);
   this.loadTenantConfig();
  setTimeout(()=>{
    if(this.app.leadForm){
      this.show = true;
      this.app.leadForm = false;
    }
  }, 4000)

  
  let emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"


  this.requestCallBackForm = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[^<>!1234567890]*$')]],
    email: ['', [Validators.required,Validators.pattern(emailPattern)]],
    mobileNo: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(10)]],
    pincode: ['', [Validators.pattern("[0-9]*"), Validators.minLength(6)]],
    productOfInterest: [''],
    callDate: ['', Validators.required],
    callTime: ['', Validators.required],
    sourceName: ['Store Locator Home - Request Callback'],
    consent: [false, Validators.requiredTrue],
    storeId:[""]
  });

  this.api.getProductInterest().subscribe((data:any) => {
    this.productInterest = data;
    console.log("Product of Interest: ", this.productInterest);
  });
  
    this.loadTrackingScript();  
 }
 loadTenantConfig() {
  this.api.getTenantContent(this.app.tenantId).subscribe((data) => {
    this.consentText = data.storeLocator.consentText;
    let content = data.storeLocator;
    content.bannerImg.mobile.sort((a:any, b:any) => parseInt(a.order) - parseInt(b.order));
    content.bannerImg.desktop.sort((a:any, b:any) => parseInt(a.order) - parseInt(b.order));

    var banner = $('#banner');
    let banners = [];
    if (window.innerWidth <= 800) {
        banners = content.bannerImg.mobile;
    } else {
        banners = content.bannerImg.desktop;
        console.log("banners:", banners);
    }

    if (!content.bannerImg.arrows) {
        $(".btnPrev").hide();
        $(".btnNext").hide();
    }
    for (var offer of banners) {
        var image = $('<img>').attr('src', offer.url).addClass('half-image');
        var anchor = $('<a>').attr('href', offer.ctaUrl).attr('target', '_blank').append(image);
        var bannerImage = $('<div>').addClass('banner-img1').append(anchor);
        banner.append(bannerImage);
    }
    if (banners.length > 1) {
        banner.slick({

            autoplay: content.bannerImg.autoplay, // automatically cycle through images
            autoplaySpeed: content.bannerImg.autoplaySpeed, // change image every 5 seconds
            infinite: content.bannerImg.infinite, // enable infinite looping
            speed: content.bannerImg.speed, // animation speed
            dots: content.bannerImg.dots, // Dots
            arrows: content.bannerImg.arrows,
            prevArrow: banner.parent().find('.btnPrev'),
            nextArrow: banner.parent().find('.btnNext'),//Arrows
        });
    }  
  });
    
}

   
get requestForm() {
  return this.requestCallBackForm.controls;
}

 
  ngAfterViewInit(): void {
    this.app.locationOn.subscribe((val) => {
      let tenant = this.app.tenantId;
      let lat = this.app.latitude;
      let lang = this.app.longitude;
      let distance = this.app.distance;
      let record = this.app.record;
      let page = 0;
      let type = this.app.searchType;
      this.app.locationOffOn = val;
      if (this.app.locationOffOn === true) {
        this.util._loading.next(true)
        this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).subscribe((data) => {
          this.util._loading.next(false)
          this.storeInfo = data;
          this.sharedInfoService.page.next(1);
        }, (err) => {
          this.util._loading.next(false)
          console.log(err)
        });
      }
    });

    this.meta.updateTag(  
      { name: 'description', content: 'Dell India’s official store locator. Here are Dell Exclusive Stores and Dell Reseller Stores. Also, look for Dell Stores near you & get automated directions. Contact the store, Enquire prices, etc.' },  
      'name=description'  
    ) 
    this.meta.updateTag({
       name: 'keywords', content: 'Store Locator for Dell' 
    }, 'name=keywords')

    this.title.setTitle("Dell India's official store locator - Dell Exclusive Stores") 
  }

  close(){
    console.log(this.show);
    
    this.show = false;
    this.app.leadForm = false;
    this.showPopup=false;
  }
 


  close1(){
    console.log(this.show);
    this.requestCallBackForm.reset();
    
    this.show = false;
    this.app.leadForm = false;
    this.showPopup=false;

  }
  maxLengthTen(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 10)
    return false
  }

  maxLengthSix(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 6)
    return false
  }

  submitted = false;
 
  submitText : string = 'submit'
  onRequestCallSubmit(event :any,content : any){
    event.preventDefault();
    this.submitted = true;
    this.requestCallBackForm.patchValue({
      "storeId": this.storeId,
      "sourceName": this.app.sourceName1
    })
    // this.formattedDate = this.datePipe.transform(this.requestCallBackForm.value.date, 'dd-MM-yyyy');
    const date = new Date(this.requestCallBackForm.value.callDate);
    this.formattedDate = this.formatDate(date);
    let requestLead = new RequestLead(
      this.requestCallBackForm.value.name,
      this.requestCallBackForm.value.email,
      this.requestCallBackForm.value.mobileNo,
      this.requestCallBackForm.value.pincode,
      this.requestCallBackForm.value.productOfInterest,
      this.formattedDate,
      this.requestCallBackForm.value.callTime,
      this.requestCallBackForm.value.consent,
      this.requestCallBackForm.value.sourceName,
      this.requestCallBackForm.value.storeId,
    )
    // this.app.loadPixelScript(this.requestCallBackForm.value.pincode,"");
    // let sourceName = this.requestCallBackForm.value.sourceName;
     if(this.requestCallBackForm.valid){
      let sourceName = this.requestCallBackForm.value.sourceName;
      this.submitText = "Loading..."
      this.app.createIframe();
      this.api.saveRequestCallBackLead(requestLead, this.app.tenantId,sourceName).subscribe((data:any) => {
        this.submitText = "Submit"
        if (data.status == "SUCCESS") {
          this.submitted = false;
          let name = this.requestCallBackForm.value.name;
          this.router. navigate(['thankyou'], {
            queryParams: { username: name, thankYouPage  : 1 },
            queryParamsHandling: 'merge',
          }); 
          // this.description = data.desc;
          // this.modalService.open(content, { windowClass: "modalCls" })
          // this.enquiryForm.reset();
        } else {
          this.description = data.desc;
          this.modalService.open(content, { windowClass: "modalCls" })
        }
      },
      err =>{
        this.submitText = "Submit";
        this.submitted = false;
        console.log(err);
        
      })
     }
  }
  openDialog() {
    this.showPopup =true;
    this.dialog.open(this.requestCallBack, {
      width: '500px',
      height: '600px'
    });
  }

  handleOpenPopup(event:any){
  const { storeId, showPopup } = event;
  this.showPopup = showPopup;
  this.storeId = storeId;
  console.log('Item ID:', this.storeId);
  }
  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  ngOnDestroy(): void {
    this.app.leadForm = false;
    this.removeTrackingScript();
  }

  loadTrackingScript() {
  
    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.text = `
      ttd_dom_ready(function() {
        if (typeof TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new TTDUniversalPixelApi();
          universalPixelApi.init("ev5teqd", ["cs2q5nq"], "https://insight.adsrvr.org/track/up");
        }
      });
    `;
    this.renderer.appendChild(document.body, this.script);
  }

  removeTrackingScript() {
    if (this.script) {
      this.renderer.removeChild(document.body, this.script);
    }
  }
  openWhatsApp()
  {
    window.open('https://del1.in/00ez9', '_blank');
  
  }
}
