<div class="stTopsection" *ngIf="storDetails">
    <div class="sttLeft">
        <div class="lcHead">
            <h2>{{storDetails.displayName}}</h2>
            <p>
                {{storDetails.address}}
            </p>
        </div>
        <div class="mobileSlider bigarrow">
            <div class="storePhotosMobile" *ngIf="storDetails.partnerImages && storDetails.partnerImages.length>0">
                <div class="spmItems" *ngFor="let si of storDetails.partnerImages; let i=index;">
                    <img [src]="si.location" alt="store Photo" />
                </div>
            </div>
        </div>
        <div class="storeAdress">
            <h3>About Store</h3>
            <p>
                {{storDetails.aboutStore}}
            </p>
        </div>
        <ol class="sdListItems iconLeft">
            <li *ngIf="storDetails.email != null && storDetails.email != ' ' ">
                <a href="mailto:{{storDetails.email}}"  class="email">{{storDetails.email}}</a>
            </li>
            <li>
                <a class="clock">
                    <em>Open Now </em>
                    <span>{{utils.getTodayStoreTimings(storDetails.timings)}} (Today)
                    </span>
                </a>
            </li>
            <li>
                <a [attr.href]="storDetails.gmapLink" target="_blank" class="getDirection">Get
                    Directions</a>
            </li>
        </ol>
        <div class="detailBtns">
            <a href="tel:{{storDetails.phoneNumber}}"   (click)="app.getDataLayervalues(storDetails, 'Get_in_touch_store')" class="yellowBtn large"><em class="whiteCall"></em> Get In
                Touch - {{storDetails.phoneNumber}}</a>
        </div>
        <div class="detailBtns">
            <button id="requestBtn" class="yellowBtn large call-back" (click)="requestCallBackClick();app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:n1qrllz&fmt=3')"> 
                Request Call Back</button>
        </div>
    </div>
    <div class="sttRight">
        <div class="shareDistance">
            <span class="sharewrap" (click)="sharemobiel()"> Share 
            </span>
        </div>
        <div class="photoGalleryDesktop" *ngIf="storDetails.partnerImages && storDetails.partnerImages.length>0">
            <h4>Store Photo Gallery</h4>
            <div class="pgCoverImage">
                <div *ngFor="let si of storDetails.partnerImages; let i=index;" class="pgItem" [id]="'pg'+(i+1)"
                    [ngStyle]="i===0?{'display': 'block'}:{}">
                    <img [src]="si.location" alt="" />
                </div>
            </div>
            <ul class="pgListToggle">
                <li *ngFor="let si of storDetails.partnerImages; let i=index;">
                    <img [src]="si.location" alt="" (click)="toggleImage($event,i)" [attr.data-href]="'pg'+(i+1)"
                        [ngClass]="i===0?'active':''" />
                </li>
            </ul>
        </div>
    </div>
</div>