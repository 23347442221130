<div class="container mbTopbanner" id="mbTopbanner">
    <div class="searchWrap">
        <div class="mainSearchWrapper">
            <span class="searchRadio">
                <button class="locateMeBtn"  tabindex="0" on-click="locateMe($event, 'currentLocation')"> 
                    <span role="button">
                        Locate Me
                    </span>
                </button>
            </span>
            <label for="dell-search" class="search-label">Search Dell locations</label>
            <input id="dell-search" type="text" #search class="mtmInput" tabindex="0" placeholder="Search Dell locations" [value]="searchValue" (keyup)="enableFindStoreBtn1($event,search.value)" />
            <ng-container *ngIf="storeList && storeList.length > 0;">
                <ul id="myUL" role="menu" aria-label="menu">
                    <li role="menuitem" class="mnu-item" *ngFor="let list of storeList; let i = index" (mousedown)="currentLocationStores(list)" 
                    (keydown.enter)="currentLocationStores(list)">
                        <img src="assets/img/searchIcon.svg" alt="" style="padding: 0 8px 0 0;"> <span>{{ list.description }}</span>
                    </li>
                </ul>
            </ng-container>
            <span class="searchBtnwrap">
          <button [disabled]="findBtn" (click)="enableFindStoreBtn(search.value); app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:pu9jpe6&fmt=3')" class="gradientBtn" ><label class="responsive-label">Find Stores</label></button>
        </span>
        </div>

    </div>
</div>
<main>
    <div class="container mobileP0" *ngIf="topbanner">
        <div class="popularCity">
            <h3 *ngIf="show">View Stores by City</h3>
            <div class="">
                <div class="popularCityslider">
                    <ul class="plList bigarrow">
                        <li>
                            <a href="#" on-click="locateMe($event,'Kolkata')">
                                <em class="Kolkata"></em>
                                <strong>Kolkata</strong>
                            </a>
                        </li>
                        <li>
                            <a href="#" on-click="locateMe($event, 'Mumbai')">
                                <em class="mumbai"></em>
                                <strong>Mumbai</strong>
                            </a>
                        </li>
                        <li>
                            <a href="#" on-click="locateMe($event,'Delhi')">
                                <em class="delhi"></em>
                                <strong>Delhi</strong>
                            </a>
                        </li>
                        <li>
                            <a href="#" on-click="locateMe($event,'Bangalore')">
                                <em class="banglore"></em>
                                <strong>Bangalore</strong>
                            </a>
                        </li>
                        <li>
                            <a href="#" on-click="locateMe($event,'Hyderabad')">
                                <em class="Hyderabad"></em>
                                <strong>Hyderabad</strong>
                            </a>
                        </li>
                        <li>
                            <a href="#" on-click="locateMe($event,'Chennai')">
                                <em class="Chennai"></em>
                                <strong>Chennai</strong>
                            </a>
                        </li>
    
                    </ul>
                </div>
            </div>
        </div>
    </div>
</main>
