<span class="menuOverly"></span>

<section class="mainBanner">
    <div class="sliderParent">

        <section id="banner">
        </section>
        <button class="slick-prev btnPrev"></button>
        <button class="slick-next btnNext"></button>
    </div>
</section>
<section class="mainBanner1">
<app-banner></app-banner></section>
<app-store-list *ngIf="storeInfo" [storeInfoDetail]="storeInfo" (openPopup)="handleOpenPopup($event)"></app-store-list>

<div class="enqueryForm requestCallBackPopup" id="getInTouch" *ngIf="showPopup">
    <div class="eqFwrap">
        <h5 class="boldLIght towLineHead">
            <strong><span>Request a call back</span></strong> Please fill in your details below and our team
            will get in touch with you.
        </h5>
        <span (click)="close1()" class="crossBtn"></span>
        <form [formGroup]="requestCallBackForm">
            <ul class="efListwrap">
                <li>
                    <span>
                        <label>Name*</label>
                        <input class="input" type="text" placeholder="Enter Name" formControlName="name" autocomplete="off"
                            maxlength="100" />
                        <small class="error1" *ngIf="requestForm.name.errors?.required && requestForm.name.dirty">
                            <sup>*</sup>Enter your name
                        </small>
                        <small class="error1" *ngIf="requestForm.name.errors?.minlength && requestForm.name.dirty && !requestForm.name.errors?.pattern">
                            <sup>*</sup>Name must be 2 characters long
                        </small>
                        <small class="error1" *ngIf="requestForm.name.errors?.pattern && requestForm.name.dirty">
                            <sup>*</sup>Please enter a valid name
                        </small>
                    </span>
                </li>
                <li>
                    <span>
                        <label>Mobile*</label>
                        <input class="input" type="text" placeholder="+91" 
                        (input)="maxLengthTen($event)" inputmode="numeric" autocomplete="off"
                        maxlength="10" formControlName="mobileNo"  />
                        <small class="error1" *ngIf="requestForm.mobileNo.errors?.required && requestForm.mobileNo.dirty">
                            <sup>*</sup>Please enter your Mobile number
                        </small>
                        <small class="error1" *ngIf="requestForm.mobileNo.errors?.minlength && requestForm.mobileNo.dirty">
                            <sup>*</sup>Please enter 10 digit Mobile Number
                        </small>
                    </span>
                </li>

                <li>
                    <span>
                        <label>Pincode</label>
                        <input class="input" type="text" placeholder="Pincode"  appOnlynumber
                            formControlName="pincode" maxlength="6"
                            (input)="maxLengthSix($event)" inputmode="numeric" autocomplete="off"
                            />
                        <small class="error1" *ngIf="requestForm.pincode.errors?.required && requestForm.pincode.dirty">
                            <sup>*</sup>Please enter your Pincode
                        </small>
                        <small class="error1" *ngIf="requestForm.pincode.errors?.minlength && requestForm.pincode.dirty">
                            <sup>*</sup>Please enter 6 digit Pincode
                        </small>
                    </span>
                </li>
                <li>
                    <span>
                        <label>Email*</label>
                        <input class="input" type="text" placeholder="Enter Email" formControlName="email"
                            maxlength="100" autocomplete="off" />
                        <small class="error1 mail-error" *ngIf="requestForm.email.errors?.required && requestForm.email.dirty">
                            <sup>*</sup>Enter your email
                        </small>
                        <small  class="error1" *ngIf="requestForm.email.errors?.pattern && requestForm.email.dirty">
                            <sup>*</sup>Please enter valid email
                        </small>
                    </span>
                </li>
            </ul>

                <ul class="efListwrap matListWrap">
                <li>
                    <span>
                        <mat-form-field>
                            <label>Product of Interest</label>
                            <mat-select formControlName="productOfInterest"  placeholder="select" class="prodselect">
                                <mat-option *ngFor="let product of productInterest" [value]="product.name">
                                    {{ product.name }}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </span>
                </li>
                <li>
                    <span>
                        <mat-form-field >
                            <label style="margin-bottom: 0px;">Date*</label>
                            <input matInput [matDatepicker]="picker" class="datediv" formControlName="callDate" [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                          <small class="error1 mat-error" *ngIf="requestForm.callDate.errors?.required && requestForm.callDate.dirty">
                            <sup>*</sup>Please enter date
                          </small>
                    </span>
                </li>
                <li>
                    <span>
                        <mat-form-field>
                            <label>Time*</label>
                            <mat-select formControlName="callTime"  placeholder="select" class="timeselect">
                                <mat-option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</mat-option>
                                <mat-option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</mat-option>
                                <mat-option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</mat-option>
                                <mat-option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</mat-option>
                                <mat-option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</mat-option>
                                <mat-option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</mat-option>
                                <mat-option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</mat-option>
                                <mat-option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</mat-option>
                                <mat-option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</mat-option>
                                <mat-option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</mat-option>
                                
                            </mat-select>
                          </mat-form-field>
                          <small class="error1 mat-error" *ngIf="requestForm.callTime.errors?.required && requestForm.callTime.dirty">
                            <sup>*</sup>Please select time
                          </small>
                    </span>
                </li>
                <li>
                    <span class="btn-call">
                        <!-- <div class="sub1"> -->
                            <button style="width: 150px;height: 55px;" [disabled]="!this.requestCallBackForm.valid" class="formBtn" (click)="onRequestCallSubmit($event,content);app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')" 
                            [ngClass]="{'btn btn-secondary': !requestCallBackForm.valid, 'yellowBtn large': requestCallBackForm.valid}">Submit
                            </button>
                            <!-- <small></small> -->
                        <!-- </div> -->
                    </span>
                </li>
            </ul>
            <div class="submitBtncheckobx1">     
                <div [innerHTML]="consentText" [ngStyle]="{'font-size': '12px'}"></div>
                
                <div style="display: flow-root">
                <input type="checkbox" formControlName="consent" class="checkbox">
                    <span></span>
                    <em style="font-size: 12px;">  <sup></sup>I would also like to recieve a text message (SMS) with the location of the nearest store.</em>
                </div>
            </div>

        </form>
    </div>
    <div class="thankYoumsg" style="display: none">
        <h3>Thank You</h3>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus iaculis.
        </p>
        <a href="#" class="crossBtn"></a>
    </div>
</div> 


<ng-template #content let-c="close" let-d="dismiss">
  <div class="thankYoumsg">
      <button class="btn-close thankyou-btn-close" aria-label="Close" (click)="d('Cross click')"></button>
      <h3 class="modal-title" id="modal-basic-title">Thank You</h3>

  </div>
  <div class="modal-body text-center">
      <p><strong>{{description}}</strong></p>
  </div>
</ng-template>