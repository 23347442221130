import { Component, Injector, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/utilities.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Lead } from '../models/lead';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { RequestLead } from '../models/requestLead';

declare var $: any;
declare var HcFormClass:any;


@Component({
  selector: 'app-store-site',
  templateUrl: './store-site.component.html',
  styleUrls: ['./store-site.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class StoreSiteComponent implements OnInit {
  mainContent: any;
  storeFormattedAddress: any;
  storDetails: any;
  submitText: any = "Submit";
  storeImages : any[] = [];
  title: Title;
  fb: FormBuilder;
  modalService: NgbModal;
  config: NgbModalConfig;
  description: string = "";
  enquiryForm!: FormGroup;
  requestCallBackForm!: FormGroup;
  app: AppComponent;
  renderer: Renderer2;
  isThankYouPage : boolean = false;
  userName : string = "";
  showRequestCallBack: boolean =false;
  productInterest: any[] = [];
  isDisabled: boolean = true;
  formattedDate: any;
  minDate = new Date();
  consentText: any



  constructor(private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private route: Router,
    public utils: UtilitiesService,
    public injector: Injector,
    private meta: Meta) {
    this.title = this.injector.get<Title>(Title);
    this.fb = this.injector.get<FormBuilder>(FormBuilder);
    this.modalService = this.injector.get<NgbModal>(NgbModal);
    this.config = this.injector.get<NgbModalConfig>(NgbModalConfig);
    this.app = this.injector.get<AppComponent>(AppComponent);
    this.renderer = this.injector.get<Renderer2>(Renderer2);
    // get path param from url  
    this.activatedRoute.params.subscribe(data => {
      this.storeFormattedAddress = data.formattedAddress;
      if(this.storeFormattedAddress){
        this.getStoreDetails();
      }
 
    })


    if (this.app.isBrowser) {
      window.scrollTo(0, 0);
      this.renderer.addClass(document.body, 'listingPage')
    }

    this.minDate.setDate(this.minDate.getDate());
  }


  setTrackingInfo(storeName: string): void {
    var tracker = (<any>window)['Dell'];
    tracker.Metrics.sc.pageName = storeName;
    (<any>window)['Dell'] = tracker;
  }

  thankYouPage : number = 0;


  ngOnInit() {
    this.utils.currentSocialMedia.subscribe((data: any) => {
      this.mainContent = data;
    })

    let emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    this.enquiryForm = this.fb.group({
      name: ['', [Validators.minLength(2), Validators.pattern('^[^<>!1234567890]*$')]],
      email: ['', [Validators.pattern(emailPattern)]],
      mobileNo: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(10)]],
      pincode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(6)]],
      sourceName: ['Store Locator'],
      storeId: [""],
      storeName: [""],
      phoneNumber: [""],
      storeGMBLink: [""],
      consent: [false, Validators.requiredTrue]
    });
    this.requestCallBackForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[^<>!1234567890]*$')]],
      email: ['', [Validators.required,Validators.pattern(emailPattern)]],
      mobileNo: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(10)]],
      pincode: ['', [Validators.pattern("[0-9]*"), Validators.minLength(6)]],
      productOfInterest: [''],
      callDate: ['', Validators.required],
      callTime: ['', Validators.required],
      sourceName: ['Partner Page - Request Callback'],
      consent: [false, Validators.requiredTrue],
      storeId:[""]
    });

    this.apiService.productsObserable.subscribe(data =>{
      if(data != null) {
        this.storeImages = [...Array(20)].reduce((acc) => acc.concat([...data]), []);
      setTimeout(()=>{
        this.productSlider();
      },300)
    }
    })

    this.apiService.getProductInterest().subscribe((data:any) => {
      this.productInterest = data;
    });

 
  }
 
  focusOnSection(){
    if(this.showRequestCallBack){
      const section = document.getElementById('getInTouch1');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        console.error('Element with ID "getinTouch1" not found.');
      }
    }
  }


  getStoreDetails(){
    if (this.storeFormattedAddress && this.storeFormattedAddress !== 'thankyou') {
      this.isThankYouPage = false;
      this.utils._loading.next(true);
      this.apiService.getformattedAddress(this.storeFormattedAddress, this.app.tenantId).subscribe((data) => {
        this.utils._loading.next(false);
        this.storDetails = data;
        if(this.storDetails.reviews && this.storDetails.reviews.length > 0){
          this.apiService.reviews.next(this.storDetails.reviews);
        }else{
          this.apiService.getReviews(data.lat, data.lng).subscribe((review : any) =>{
            this.apiService.reviews.next(review);
          })
        }
        this.title.setTitle(this.storDetails.displayName);
        this.meta.updateTag({ name: 'title', content: this.storDetails.name + ' | ' + this.storDetails.seoTitle });
        this.meta.updateTag({ property: 'og:title', content: this.storDetails.name + ' | ' + this.storDetails.seoTitle })
        this.meta.updateTag({ name: 'description', content: this.storDetails.seoDescription });
        this.meta.updateTag({ property: 'og:description', content: this.storDetails.seoDescription });
        this.meta.updateTag({ name: 'keywords', content: this.storDetails.seoKeywords });
        this.meta.updateTag({ property: 'og:keywords', content: this.storDetails.seoKeywords });
       
    let seo = document.createElement('script');
    seo.type = 'application/ld+json';
    // analytics.async = true;
    seo.innerHTML = this.storDetails.seo;
    document.getElementsByTagName('head')[0].appendChild(seo);

    this.apiService.getTenantContent(this.app.tenantId).subscribe((data) => {
      const responseJson = data;
      this.consentText = data.storeLocator.consentText;2
      console.log("text ",this.consentText);
      let faqSchema = responseJson.faqSchema;
      const jsonFaq = JSON.stringify(faqSchema);
      
      console.log(faqSchema);
      let faq = document.createElement('script');
      faq.type = 'application/ld+json';
      faq.innerHTML = jsonFaq;
      document.getElementsByTagName('head')[0].appendChild(faq);

    })

        this.getStoreTemplate();
        this.setTrackingInfo(this.storDetails.displayName);
        //this.initializeForm();
        
      },
        (err) => {
          console.log(err);
          this.utils._loading.next(false);
          this.route.navigate(['store/not-found/'+this.storeFormattedAddress]);
        })
    }else{
      this.utils._loading.next(false);
      this.activatedRoute.queryParams.subscribe(params => {
        if (params['thankYouPage'] && params['thankYouPage'] == 1) {
          this.removeTrackingScript();
          this.loadTrackingScriptOffer();
          this.isThankYouPage = true;
          const username = params['username'];
          this.thankYouPage = params['thankYouPage'];

           if(params['leadId']){
            this.addDynamicScript(params['leadId']);
           }

            this.userName = username
            this.route.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: { username: null, thankYouPage : null, leadId: null },
              queryParamsHandling: 'merge',
            });    
        }else{
          this.utils._loading.next(false);
          if(params['leadId']){
            this.addDynamicScript(params['leadId']);
           }
          if(this.thankYouPage == 1) {
            this.removeTrackingScript();
            this.loadTrackingScript();
            this.route.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: { leadId: null },
              queryParamsHandling: 'merge',
            });    
            return;
          }
          this.route.navigate(['store/not-found/'+this.storeFormattedAddress]);
        }
      });
  
    }
  }



   script1!: HTMLScriptElement;
   script2!: HTMLScriptElement;

   addDynamicScript(LeadId : string) {
    this.script1 = this.renderer.createElement('script');
    this.script1.type = 'text/javascript';
    this.script1.text = `
      var afoffer_id = 22753;
      var afstatus=1;
      var afid='${LeadId}';
    `;
    this.renderer.appendChild(document.body, this.script1);

    this.script2 = this.renderer.createElement('script');
    this.script2.type = 'text/javascript';
    this.script2.src = 'https://app.adstracking.io/track.js';
    this.renderer.appendChild(document.body, this.script2);
  }

  removeDynamicScript() {
    if (this.script1) {
      this.renderer.removeChild(document.body, this.script1);
    }
    if (this.script2) {
      this.renderer.removeChild(document.body, this.script2);
    }
  }

   script!: HTMLScriptElement;

  loadTrackingScript() {
    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.text = `
      ttd_dom_ready(function() {
        if (typeof TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new TTDUniversalPixelApi();
          universalPixelApi.init("ev5teqd", ["cjfbhss"], "https://insight.adsrvr.org/track/up");
        }
      });
    `;
    this.renderer.appendChild(document.body, this.script);
  }

  loadTrackingScriptOffer() {
    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.text = `
      ttd_dom_ready(function() {
        if (typeof TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new TTDUniversalPixelApi();
          universalPixelApi.init("ev5teqd", ["a15of2x"], "https://insight.adsrvr.org/track/up");
        }
      });
    `;
    this.renderer.appendChild(document.body, this.script);
  }

  removeTrackingScript() {
 
    if (this.script) {
      this.renderer.removeChild(document.body, this.script);
    }
  }


initializeForm(){
  var formPlugin = new HcFormClass({"containerId":
 "dynamicForm","appCode":"test1234",'listClass':'horizontal',
 "actionUrl": "/api/ui/landing-page", "fields": [
  {
    "field": "input",
    "type": "hidden",
    "name": "storeId",
    "value":this.storDetails.id,
    },
    {
      "field": "input",
      "type": "hidden",
      "name": "sourceName",
      "value":this.app.sourceName
      },
      {
        "field": "input",
        "type": "hidden",
        "name": "displayName",
        "value":this.storDetails.displayName
        },
        {
          "field": "input",
          "type": "hidden",
          "name": "phoneNumber",
          "value":this.storDetails.phoneNumber
          },
          {
            "field": "input",
            "type": "hidden",
            "name": "gmapLink",
            
            "value":this.storDetails.gmapLink
            },
{
"field": "input",
"type": "text",
"length": "15",
"placeholder": "Name",
"name": "name",
"formControlName":"name",
"class":"input"
},
{
  "field": "input",
  "type": "number",
  "length": "15",
  "placeholder": "Mobile",
  "name": "mobile",
  "formControlName":"mobile",
  "class":"input"
  },
{
"field": "input",
"type": "text",
"length": "50",
"placeholder": "Email",
"name": "email",
"class":"input",
"formControlName":"email"
},
{
"field": "input",
"type": "text",
"length": "250",
"placeholder": "Pincode",
"name": "pincode",
"formControlName":"pincode",
"class":"input",
},
{
  "field": "button",
"type": "submit",
"class":"submitBtn gradientBtn formBtn",
"innerHTML":"submit"
},
]
});
formPlugin.init();
}

  get myForm() {
    return this.enquiryForm.controls;
  }
  get requestForm() {
    return this.requestCallBackForm.controls;
  }
 
  goBack() {
    this.route.navigate(["/"], { queryParamsHandling: "merge" })
  }
  getStoreTemplate() {
    if (this.app.isBrowser) {
      $(document).ready(function () {
        $(".grList").slick({
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
          responsive: [{
            breakpoint: 700,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,

              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,

              dots: true,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,

              dots: true,
            },
          },
          ],
        });
      })
      this.breakpointObserver.observe([
        "(max-width: 768px)"
      ]).subscribe((result: BreakpointState) => {
        if (result.matches) {
          $(".storePhotosMobile").slick({
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            responsive: [{
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              },
            },],
          });
        }
      });
    }
  }

  productSlider(){
    $(document).ready(function () {
      $(".storeList").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        loop : true,
        autoplay: true,
        speed : 100,
        autoplaySpeed:2000,
        cssEase: "linear",
        dots: false,
        responsive: [{
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        ],
      });
    })
  }

  submitted = false;
  // requestSubmitted = false;
  onSubmit(event: any, content: any) {
    event.preventDefault()
    console.log(this.enquiryForm.value.consent);
    this.submitted = true;
    this.enquiryForm.patchValue({
      "storeId": this.storDetails.id,
      "sourceName": this.app.sourceName,
      "displayName": this.storDetails.displayName,
      "phoneNumber": this.storDetails.phoneNumber,
      "gmapLink": this.storDetails.gmapLink
    })
    

    console.log("this.enquiryForm.valid ", this.storDetails.phoneNumber);
    if (!this.enquiryForm.valid) return;
    let lead = new Lead(
      this.enquiryForm.value.name,
      this.enquiryForm.value.email,
      this.enquiryForm.value.mobileNo,
      this.enquiryForm.value.pincode,
      this.enquiryForm.value.sourceName,
      this.enquiryForm.value.storeId,
      this.enquiryForm.value.consent
    )

    if (this.enquiryForm.valid) {
      this.submitText = "Loading..."
      this.apiService. saveLead(lead, this.app.tenantId)
      .pipe(
        debounceTime(700)
      ).subscribe(data => {
        this.submitText = "Submit"
        if (data.status == "SUCCESS") {
          this.submitted = false;
          let name = this.enquiryForm.value.name;
          this.isThankYouPage = true;
          this.storDetails = null;
          this.route.navigate(['thankyou'], 
          { queryParams: { username:  name, thankYouPage  : 1 },
          queryParamsHandling: 'merge'
        }); 

          // this.description = data.desc;
          // this.modalService.open(content, { windowClass: "modalCls" })
          // this.enquiryForm.reset();
        } else {
          this.description = data.desc;
          this.modalService.open(content, { windowClass: "modalCls" })
        }
      }, err => {
        this.submitText = "Submit"
        this.submitted = false;
      })
    }
  }

  onRequestCallSubmit(event: any, content: any) {
    event.preventDefault()
    this.submitted = true;
    this.requestCallBackForm.patchValue({
      "storeId": this.storDetails.id,
      "sourceName": this.app.sourceName2
    })
    

    // console.log("this.enquiryForm.valid ", this.storDetails.phoneNumber);
    const date = new Date(this.requestCallBackForm.value.callDate);
    this.formattedDate = this.formatDate(date);
    if (!this.requestCallBackForm.valid) return;
    let requestLead = new RequestLead(
      this.requestCallBackForm.value.name,
      this.requestCallBackForm.value.email,
      this.requestCallBackForm.value.mobileNo,
      this.requestCallBackForm.value.pincode,
      this.requestCallBackForm.value.productOfInterest,
      this.formattedDate,
      this.requestCallBackForm.value.callTime,
      this.requestCallBackForm.value.consent,
      this.requestCallBackForm.value.sourceName,
      this.requestCallBackForm.value.storeId,
    )

    if (this.requestCallBackForm.valid) {
      let sourceName = this.requestCallBackForm.value.sourceName;
      this.submitText = "Loading..."
      this.app.createIframe();
      this.apiService.saveRequestCallBackLead(requestLead, this.app.tenantId, sourceName)
      .pipe(
        debounceTime(700)
      ).subscribe(data => {
        this.submitText = "Submit"
        if (data.status == "SUCCESS") {
          this.submitted = false;
          let name = this.requestCallBackForm.value.name;
          this.isThankYouPage = true;
          this.storDetails = null;
          this.route.navigate(['thankyou'], 
          { queryParams: { username:  name, thankYouPage  : 1 },
          queryParamsHandling: 'merge'
        }); 

          // this.description = data.desc;
          // this.modalService.open(content, { windowClass: "modalCls" })
          // this.enquiryForm.reset();
        } else {
          this.description = data.desc;
          this.modalService.open(content, { windowClass: "modalCls" })
        }
      }, err => {
        this.submitText = "Submit"
        this.submitted = false;
      })
    }
  }

  ngOnDestroy(): void {
    if (this.app.isBrowser) {
      this.renderer.removeClass(document.body, 'listingPage')
    }
    this.removeDynamicScript();
  }

  sharemobiel() {
    if (navigator.share) {
      navigator.share({
        title: this.storDetails.displayName,
        url: '/' + this.storDetails.formattedAddress 
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      // shareDialog.classList.add('is-open');
    }
  };

  maxLengthTen(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 10)
    return false
  }

  maxLengthSix(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 6)
    return false
  }

  convertDateToString(dateToBeConverted: string) {
    let mom = moment(dateToBeConverted, "YYYY/MM/DD HH:mm:ss").format();
    let dates = mom.split('T');
    let dart = dates[1].split('+');
    return dates[0].toString().split("-").reverse().join("/") +" "+ moment(dateToBeConverted, "YYYY/MM/DD HH:mm:ss").format("hh:mm A")
    }

    getMobileNumber(storDetails : any, vfn : string){
      return ((storDetails && storDetails.phoneNumber) ? "tel:" + storDetails : "tel:"+ vfn);
    }

    handleOpenPopup(event:any){
      const {showRequestCall} = event;
      this.showRequestCallBack = showRequestCall;
      this.focusOnSection();
      }
      formatDate(date: Date): string {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
    
        return `${year}-${month}-${day}`;
      }
}
