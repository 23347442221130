import { Component, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements AfterViewInit {
  faqs: any = [];

  isExpanded: boolean[] = []; 


initializeExpansion(): void {
    this.isExpanded = new Array(this.faqs.length).fill(false);
}


toggleAnswer(index: number): void {
    this.isExpanded[index] = !this.isExpanded[index];
}


  

  constructor(private app: AppComponent, private apiSerive : ApiService) { }
  mainContent: any

  ngOnInit() : void {
    let baseURL = "";

    if(window.location.hostname==="127.0.0.1" || window.location.hostname===""){
        baseURL = "https://dell-dev.hashconnect.in"
    }

    
    this.apiSerive.faqObserable.subscribe( data =>{
      this.faqs = data;
    })
  }

  ngAfterViewInit(): void {

    $(document).ready(function () {
      $(".faqAccordion > li > .answer").hide();
      $(".faqAccordion > li").click((obj: any) => {
        if ($(obj.currentTarget).hasClass("active")) {
          $(obj.currentTarget).removeClass("active").find(".answer").slideUp();
        } else {
          $(".faqAccordion > li.active .answer").slideUp();
          $(".faqAccordion > li.active").removeClass("active");
          $(obj.currentTarget).addClass("active").find(".answer").slideDown();
        }
        return false;
      });
      $(".faqAccordion > li .answer").click(function (e: any) {
        e.stopPropagation();
      });
    });

  }
}
