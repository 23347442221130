import { Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent { 
  contactUsdd: boolean = false;

footer : any = {}   
  constructor(public app : AppComponent, private api : ApiService){
    this.api.footerObserable.subscribe( data =>{
      if(data){
        this.footer = data;
      }
    })
  }


  toggleContactShow() {
    this.contactUsdd = !this.contactUsdd;
    
  }
  scrollTop(){
    window.scrollTo(0,0)
  }
}
