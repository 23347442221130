import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  createIFrame(src: string): void {
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(iframe, 'src', src);
    this.renderer.setAttribute(iframe, 'scrolling', 'no');
    this.renderer.setAttribute(iframe, 'frameborder', '0');
    this.renderer.setStyle(iframe, 'width', '1px');
    this.renderer.setStyle(iframe, 'height', '1px');

    const body = document.body;
    if (body.firstChild) {
      this.renderer.insertBefore(body, iframe, body.firstChild);
    } else {
      this.renderer.appendChild(body, iframe);
    }
  }
}
