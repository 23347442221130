import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() {
    //Do nothing
  }
  socialMedia = new BehaviorSubject({});
  currentSocialMedia: Observable<any> = this.socialMedia.asObservable();

  _loading = new BehaviorSubject(false);
  loading: Observable<boolean> = this._loading.asObservable();

  storeList: EventEmitter<any> = new EventEmitter<any>();

  page = new BehaviorSubject(1);
  currentPage = this.page.asObservable();

  public getTodayStoreTimings(timings: any): any {
    let newday = JSON.parse(timings)
    const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let weekDay = weekDays[new Date().getDay()];
    return newday[weekDay];
  }

  public getSortStoreTimings(timings: any) {

    const sorter = {
      "mon": 1,
      "tue": 2,
      "wed": 3,
      "thu": 4,
      "fri": 5,
      "sat": 6,
      "sun": 7
    }
    let newday = JSON.parse(timings)
    let days = Object.keys(newday);

    /* days.sort((a, b) => {
      let day1 = a.toLowerCase();
      let day2 = b.toLowerCase();
      //return sorter[day1] - sorter[day2];
    }); */
  }
}
