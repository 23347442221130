export class RequestLead {

    private email: string;
    private mobileNo: string;
    private name: string;
    private pincode: string;
    private productOfInterest: string;
    private callDate : string;
    private callTime : string;
    private sourceName: number;
    private storeId: number;
    private consent: boolean;



    constructor(_name: string, _email: string, _mobileNo: string, _pincode: string,_productOfInterest: string,_callDate: string, _callTime: string, _consent: boolean, _sourceName: number, _storeId: number) {
        this.name = _name;
        this.email = _email;
        this.mobileNo = _mobileNo;
        this.pincode = _pincode;
        this.productOfInterest = _productOfInterest;
        this.callDate = _callDate;
        this.callTime = _callTime;
        this.consent = _consent;
        this.sourceName = _sourceName;
        this.storeId = _storeId;
    }

}