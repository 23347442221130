import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textSpliteByHyphenPipe'
})
export class TextSpliteByHyphenPipe implements PipeTransform {
  transform(value: string, split: string, address: number): string {
    if(address == 0){
      const storeName = value.split(split)
      return storeName[address];
    }else{
      const storeName = value.split(split)
      return storeName.splice(address).toString().split(",").join(" ");
    }
   
  }
}
