import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Component, Inject, Input, OnInit, ViewChild, AfterViewInit, Renderer2, SimpleChanges, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { SharedInfoService } from 'src/app/sharedInfo.service';
import { UtilitiesService } from 'src/app/utilities.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps'
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit, AfterViewInit {
  @Output() openPopup: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('requestCallBack') requestCallBack: any;
  isShown: boolean = false;
  apiLoaded: Observable<boolean> | undefined;

  markerPositions: google.maps.LatLngLiteral[] = [];
  center: { lat: number; lng: number; } | undefined;
  @ViewChild("gMap") gMap?: GoogleMap;
  @ViewChild(MapInfoWindow, {
    static: false
  }) infoWindow!: MapInfoWindow;
  mapTypeId = google.maps.MapTypeId.ROADMAP;
  mapTypeControl = false;
  disableDefaultUI: boolean = false;
  markers: any[] = [];
  show:boolean =false;


  styledMapType = new google.maps.StyledMapType(
    [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }

    ]
  );


  infoContent = ""

  ngAfterViewInit(): void {
   this.storeMaps();
   $('html, body').scrollTop($('#scroll').offset().top - 100);
  }

storeMaps(){
  if (this.storeInfoDetail.length > 0) {
    var bounds = new google.maps.LatLngBounds();
    for (let store of this.storeInfoDetail) {
      const pos = { lat: Number(store.lat), lng: Number(store.lng) };
      this.markers.push({
        position: pos,
        draggable: true,
        info: store,
        icon: './assets/img/dell-location.png'
      })
      bounds.extend(pos);
    }
    this.gMap?.fitBounds(bounds);

  }
}


  openInfo(marker: MapMarker, content: any) {

    this.infoContent = `
    <div class="header_info" >
   <a href="${content.formattedAddress }" queryParamsHandling="merge">
        ${content.displayName}
    </a>
    </div>
    <div class="store-info">
    <div class="store-time">  
    <a href="tel:${content.phoneNumber}" class="call">Get In Touch</a>
     <p>${this.utils.getTodayStoreTimings(content.timings)}
    </p>
    </div>
    <div class="GMP_link">
    <a href="${content.gmapLink}" class="getDirection" target="_blank" >Get Direction</a>
    </div>
    </div>
    <div class="address_info">
     <p> ${content.address}</p>
    </div>
`;
    this.infoWindow.open(marker);
  }
  toggleShow() {
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.renderer.setProperty(this.document.querySelectorAll('#viewOnMapBtn')[0], "innerHTML", "View List");
    } else {
      this.renderer.setProperty(this.document.querySelectorAll('#viewOnMapBtn')[0], "innerHTML", "View On Map");
    }
  }

  @Input() storeInfoDetail: any;
  page: number = 1;
  loadMore: boolean = false;
  loadMoreText: string = "Load More Stores";
  result!: boolean;
  MobStoreList: number = 0;
  distanceForStores :any;
  enquiryForm!: FormGroup;



  constructor(public app: AppComponent,
    private api: ApiService,
    public utils: UtilitiesService,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private el: ElementRef) { }

  ngOnInit(): void {
    this.document.body.classList.add('listingPage');
    if (this.storeInfoDetail.length > 0) {
      this.getTemplate();
      this.api.getStoreListWithInKm(this.app.searchType, this.app.latitude, this.app.longitude, this.app.distanceForTotalStore).subscribe((data) =>{
        this.distanceForStores = data[0];
      })
    }
    let onInit = this
    $('.sharewrap').on("click", function(e:any){
     let displayName =  $(e.target).data("displayName")
     let formattedAddress = $(e.target).data("formattedAddress")
     onInit.sharemobiel(displayName,formattedAddress)
    })
  }

  loadMoreStoresMob() {
    let tenant = this.app.tenantId;
    let lat = this.app.latitude;
    let lang = this.app.longitude;
    let distance = this.app.distance;
    let record = this.app.record;
    let type = this.app.searchType;
    var page = this.page;
    this.loadMoreText = "Loading...  ";
    this.utils._loading.next(true)
    if (this.app.placeId.length > 0) {
      this.api.getStoreDetailsByPlaceId(tenant, this.app.placeId, distance, record, type, page).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        // $("#lastPage").remove()
        $(".slListing").slick("unslick");
        $('.slListing').css('visibility', "hidden");
        data.forEach((item: any) => {
          $(`<li class="listingCard">
            <strong class="storeDistance">
                <em class="sdIcon flag"></em>
                <span> Distance - ${Number.parseFloat(item.distance).toFixed(2)} Km</span>
                <span class="sharewrap" data-displayName='${item.displayName}' data-formattedAddress ='${item.formattedAddress}'>Share
                </span>
                </strong>
            <div class="lcHead">
                <h2>
                    <a href="${item.formattedAddress }" queryParamsHandling="merge"  onClick="${this.app.getDataLayervalues(item, 'Get_in_touch_store')}">   ${item.displayName.split('-')[0]} <small>${item.displayName.split('-')[1]}
                    <span class="sr-only"> ${item.displayName.split('-')[0]} - ${item.displayName.split('-')[1]}</span>
                    </small>
                    </a>
                </h2>
            </div>
            <div class="storeAdress">
                <p>
                    ${item.address}
                </p>
            </div>
            <ol class="sdListItems">
                <li>
                    <abbr>
                        <span>Open From</span>
                        <em>${this.utils.getTodayStoreTimings(item.timings)}</em>
                    </abbr>
                </li>
                <li>
                    <a href="tel:${item.phoneNumber}" class="call">
                    
                    <span class="sr-only">Contact for ${item.displayName.split('-')[1]} on</span>
                    ${item.phoneNumber}</a>
                </li>
                <li>
                    <a href="${item.gmapLink}" target="_blank" class="getDirection">
                     Get Directions <span class="sr-only"> for ${item.displayName.split('-')[1]} </span></a>
                </li>
                <li>
                    <a href="${item.formattedAddress }" queryParamsHandling="merge" class="websiteLink" onClick="${this.app.getDataLayervalues(item, 'Get_in_touch_store')}">
                    Store Website
                    <span class="sr-only"> for ${item.displayName.split('-')[1]} </span>
                    </a>
                </li>
            </ol>
            <a href="tel:${item.phoneNumber}" style="display: flex;justify-content: center;" onClick="${this.app.getDataLayervalues(item, 'Get_in_touch_store')}" class="gradientBtn">Get In Touch
            <span class="sr-only"> for ${item.displayName.split('-')[1]} </span>
            </a>
        </li>`).insertBefore(".listingCard:last");
        })

        this.getTemplate()
        this.MobStoreList = this.MobStoreList + data.length
        setTimeout(() => {
          $('.slListing').slick('slickGoTo', this.MobStoreList);
          $('.slListing').css('visibility', "visible");
          this.utils._loading.next(false)
        }, 200)

       this.storeMaps();

      }, (err) => {
        console.log(err)
        this.utils._loading.next(false)
      });
    } else {
      this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        // $("#lastPage").remove()
        $(".slListing").slick("unslick");
        $('.slListing').css('visibility', "hidden");
        data.forEach((item: any) => {
          $(`<li class="listingCard">
            <strong class="storeDistance">
                <em class="sdIcon flag"></em>
                <span> Distance - ${Number.parseFloat(item.distance).toFixed(2)} Km</span>
                <span class="sharewrap" data-displayName='${item.displayName}' data-formattedAddress ='${item.formattedAddress}'>Share </span>
            </strong>
            <div class="lcHead">
                <h2>
                    <a href="${item.formattedAddress}" queryParamsHandling="merge">   ${item.displayName.split('-')[0]}
                    <small>${item.displayName.split('-')[1]} </small>
                    <span class="sr-only"> ${item.displayName.split('-')[0]} - ${item.displayName.split('-')[1]}</span>
                    </a>
                </h2>
            </div>
            <div class="storeAdress">
                <p>
                    ${item.address}
                </p>
            </div>
            <ol class="sdListItems">
                <li>
                    <abbr>
                        <span>Open From</span>
                        <em>${this.utils.getTodayStoreTimings(item.timings)}</em>
                    </abbr>
                </li>
                <li>
                    <a href="tel:${item.phoneNumber}" class="call">
                    <span class="sr-only">Contact for ${item.displayName.split('-')[1]} on</span>
                    ${item.phoneNumber}</a>
                </li>
                <li>
                    <a href="${item.gmapLink}" target="_blank" class="getDirection">
                    Get Directions <span class="sr-only"> for ${item.displayName.split('-')[1]} </span>
                    </a>
                </li>
                <li>
                    <a href="${item.formattedAddress}" queryParamsHandling="merge" class="websiteLink">
                    Store Website
                    <span class="sr-only"> for ${item.displayName.split('-')[1]} </span>
                    </a>
                </li>
            </ol>
            <a href="tel:${item.phoneNumber}" style="display: flex;justify-content: center;" class="gradientBtn">Get In Touch
            <span class="sr-only"> for ${item.displayName.split('-')[1]} </span>
            </a>
        </li>`).insertBefore(".listingCard:last");
        })

        this.getTemplate()
        this.MobStoreList = this.MobStoreList + data.length;
        setTimeout(() => {
          $('.slListing').slick('slickGoTo', this.MobStoreList);
          $('.slListing').css('visibility', "visible");
          this.utils._loading.next(false)
        }, 200)
        this.storeMaps();
      }, (err) => {
        console.log(err)
        this.utils._loading.next(false)
      });
    }

  }
  loadMoreStores() {
    let tenant = this.app.tenantId;
    let lat = this.app.latitude;
    let lang = this.app.longitude;
    let distance = this.app.distance;
    let record = this.app.record;
    let type = this.app.searchType;
    var page = this.page;


    this.loadMoreText = "Loading...  ";
    this.utils._loading.next(true)
    if (this.app.placeId.length > 0) {
      this.api.getStoreDetailsByPlaceId(tenant, this.app.placeId, distance, record, type, page).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        this.utils._loading.next(false);
        this.storeMaps();
      }, (err) => {
        console.log(err)
        this.utils._loading.next(false)
      });
    } else {
      this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).subscribe((data) => {
        this.loadMoreText = "Load More Stores";
        if (data.length == 0) {
          this.loadMore = true;
        } else {
          this.loadMore = false;
        }
        this.storeInfoDetail = [...this.storeInfoDetail, ...data];
        this.page++;
        this.utils._loading.next(false)
        this.storeMaps();
      }, (err) => {
        console.log(err)
        this.utils._loading.next(false)
      });
    }
  }

  getTemplate() {
    this.breakpointObserver.observe([
      "(max-width: 800px)"
    ]).subscribe((result: BreakpointState) => {
      this.result = result.matches
      if (result.matches) {
        this.slistingTemp();
      }
    })
  }

  slistingTemp() {
    $(document).ready(function () {
      $(".slListing").slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
        ],
      });
    })
  }
  ngOnDestroy(): void {
    this.document.body.classList.remove('listingPage');
  }

  sharemobiel(displayName : string,address: string) {
    if (navigator.share) {
      navigator.share({
        title: displayName,
        url: this.app.YTOriginUrl + '/' +address 
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      // shareDialog.classList.add('is-open');
    }
  };

  triggerOpenPopup(storeId:any) {
    this.openPopup.emit({ storeId: storeId, showPopup: true });
  }
  maxLengthTen(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 10)
    return false
  }

  maxLengthSix(eve: any) {
    eve.target.value = eve.target.value.replace(/[^0-9]/g, "");
    eve.target.value = eve.target.value.substring(0, 6)
    return false
  }

  close(){
    this.show=false;
  }

}

// ${item.avg_rating != null ? '<span class="starRating">' + item.avg_rating + '</span>' : ""}