<app-loading *ngIf="isLoad"></app-loading>
<div class="enqueryForm signuppopup" [formGroup]="enquiryForm" *ngIf="formPopup"  [@popupAnimation]>
    <h2 class="enqueryForm-title">Please Fill in Your Details and We'll Call You Back!</h2>
    <img (click)="popup(false)" class="close" src="assets/img/Vector.svg" alt="">
    <ul class="efListwrap">
        <li>
          <span>
            <!-- <label>Name*</label> -->
            <input class="input" type="text" placeholder="Enter Your Name*" formControlName="name" autocomplete="off"
                maxlength="100" />
        </span>
        <small class="error" *ngIf="submitted && myForm.name.errors?.required">
            <sup>*</sup>Please enter your name
        </small>
        <small class="error" *ngIf="submitted && myForm.name.errors?.minlength">
            <sup>*</sup>Name must be 2 characters long
        </small>
        <small class="error" *ngIf="submitted && myForm.name.errors?.pattern">
            <sup>*</sup>Please enter a valid name
        </small>
        </li>
        <li>
          <span>
            <!-- <label>Email</label> -->
            <input class="input" type="text" placeholder="Enter Your Email" formControlName="email" autocomplete="off"
                maxlength="100" />
            </span>
            <small class="error" *ngIf="submitted && myForm.email.errors?.required">
                <sup>*</sup>Please enter your email
            </small>
            <small class="error" *ngIf="submitted && myForm.email.errors?.pattern">
                <sup>*</sup>Please enter valid email
            </small>
    
        </li>
        <li>
          <span>
            <!-- <label>Mobile*</label> -->
            <input class="input" type="text" placeholder="+91"  autocomplete="off"
            (input)="maxLengthTen($event)"
             maxlength="10"
                formControlName="mobileNo"  inputmode="numeric"/>
        </span>
            <small class="error" *ngIf="submitted && myForm.mobileNo.errors?.required">
                <sup>*</sup>Please enter your Mobile number
            </small>
            <small class="error" *ngIf="submitted && myForm.mobileNo.errors?.minlength">
                <sup>*</sup>Please enter 10 digit Mobile Number
            </small>
     
        </li>
        <li>
          <span>
            <!-- <label>Pincode*</label> -->
            <input class="input" type="text" placeholder="Enter your Pincode*"  appOnlynumber
                formControlName="pincode" maxlength="6" autocomplete="off"
                (input)="maxLengthSix($event)" inputmode="numeric"
                 />
                </span>
            <small class="error" *ngIf="submitted && myForm.pincode.errors?.required">
                <sup>*</sup>Please enter your Pincode
            </small>
            <small class="error" *ngIf="submitted && myForm.pincode.errors?.minlength">
                <sup>*</sup>Please enter 6 digit Pincode
            </small>
  
        </li>
        <li class="fullwidth">
            <span>
        <!-- <label for="">Enter required details</label> -->
        <input type="text" formControlName="query" placeholder="Type your query here">
        </span>
     <!--   <small class="error" *ngIf="submitted && myForm.query.errors?.required">
            <sup>*</sup>This field is mandatory
        </small>  -->
    
        </li>
    </ul>
    <div>
        <span [innerHTML]="consentText" [ngStyle]="{'font-size': '12px'}" class="content-text"></span>
    </div>
    <div style="display: flow-root" class="position-relative">
        <input type="checkbox" formControlName="consent" class="checkbox1">
            <span></span>
            <em style="font-size: 12px;">  <sup></sup>I would also like to recieve a text message (SMS) with the location of the nearest store.</em>
            <small class="error consent-error" *ngIf="submitted && myForm.consent.errors?.required">
                <sup>*</sup>Please provide your consent before submitting the form.
            </small>
    </div>

    <div class="submitBtncheckobx">
            <a href="javascript:void" class="yellowBtn large-1"  (click)="onSubmit($event,content); addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')">{{submitText}}
            </a>

    </div>

   

</div> 

<app-header *ngIf="hfShow" ></app-header>
<router-outlet></router-outlet>
<section *ngIf="hfShow" class="productOffer">
    <app-section></app-section>
</section>
<app-footer *ngIf="hfShow"></app-footer>

<div class="get-touch-div" *ngIf="open">
    <button class="get-in-touch" (click)="popup(true)" >
        <img class="img-down" src="assets/img/mingcute_down-line.svg" alt="">
        <span class="get-in-touch-text">Get in Touch</span>
        <img  class="img-hand"  src="assets/img/Layer_1.svg" alt="">
    </button>
</div>
<div class="float-left whatsapp">

    <img src="assets/img/whatsapp.svg"  (click)="openWhatsApp()"  width="60" alt="">

</div>
<div  class="float-left chat-box" #chatbox>
    <img src="../assets/img/Group 11.svg" #closeIcon (click)="onMouseLeave()" class="close-img">
  <p class="chat-text" #chattext>
    Hi, Chat with us to know more latest offers....
  </p>
    <img class="chat-img" (click)="openChat()" width="60" (mouseenter)="onMouseEnter()" src="assets/img/chat.svg" alt="">
</div>

<div class="footer-bots" [ngStyle]="{'justify-content': !open ? 'space-around' : 'space-between'}">
    <div class="sub" (click)="popup(true)" *ngIf="open">
       <img src="assets/img/Frame 1.svg" alt="">
        <span>Get in touch</span>
    </div>
    <div class="sub" (click)="openChat()">
      <img src="assets/img/chat-rounded-corners 1.svg" alt="">
      <span>Chat with us</span>
   </div>
   <div class="sub">
    
      <img src="assets/img/logos_whatsapp-icon.svg" (click)="openWhatsApp()" alt="">
      <span>Whatsapp</span>
  
   </div>
  </div>
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="thankYoumsg">
        <button class="btn-close thankyou-btn-close" aria-label="Close" (click)="d('Cross click')"></button>
        <h3 class="modal-title" id="modal-basic-title">Thank You</h3>
  
    </div>
    <div class="modal-body text-center">
        <p><strong>{{description}}</strong></p>
    </div>
  </ng-template>