<section class="mainBanner12 innerPage" *ngIf="!isThankYouPage">
    <div class="container" *ngIf="storDetails">
        <div class="detailPageHead" *ngIf="storDetails.displayName">
            <h1>{{storDetails.displayName  | textSpliteByHyphenPipe:'-':
                0}}</h1>
            <div class="wrapLocationRating">
                <span class="banLocation">
                    <span>{{storDetails.displayName  | textSpliteByHyphenPipe:'-':
                        1}}</span>
                </span>
                <div class="rating" *ngIf="storDetails && storDetails.reviews.length > 0">
                    <!-- <star-rating [value]="storDetails.reviews[0].averate_rating" [totalstars]="5" checkedcolor="#F2C94C"
                        uncheckedcolor="lightgray" size="24px" [readonly]="true" #starrating>
                    </star-rating> -->
                    <div class="stars-rate">
                        <div class="main-star" style="position: relative;">
                            <span class="fa fa-star" [ngClass]="{'checked' : storDetails.reviews[0].averate_rating >= 1}"></span>
                            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(storDetails.reviews[0].averate_rating > 0 && storDetails.reviews[0].averate_rating < 1)" [ngClass]="{'checked' : (storDetails.reviews[0].averate_rating > 0 && storDetails.reviews[0].averate_rating < 1) , 'fa-star-half' : (storDetails.reviews[0].averate_rating > 0 && storDetails.reviews[0].averate_rating < 1) }"></span>
                        </div>
                        <div class="main-star" style="position: relative;">
                            <span class="fa fa-star"  [ngClass]="{'checked' : storDetails.reviews[0].averate_rating >= 2}"></span>
                            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(storDetails.reviews[0].averate_rating > 1 && storDetails.reviews[0].averate_rating < 2)" [ngClass]="{'checked' : (storDetails.reviews[0].averate_rating > 1 && storDetails.reviews[0].averate_rating < 2) , 'fa-star-half' : (storDetails.reviews[0].averate_rating > 1 && storDetails.reviews[0].averate_rating < 2) }"></span>
                        </div>
                        <div class="main-star" style="position: relative;">
                            <span class="fa fa-star"  [ngClass]="{'checked' : storDetails.reviews[0].averate_rating >= 3}"></span>
                            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(storDetails.reviews[0].averate_rating > 2 && storDetails.reviews[0].averate_rating < 3)" [ngClass]="{'checked' : (storDetails.reviews[0].averate_rating > 2 && storDetails.reviews[0].averate_rating < 3) , 'fa-star-half' : (storDetails.reviews[0].averate_rating > 2 && storDetails.reviews[0].averate_rating < 3) }"></span>
                        </div>
                        <div class="main-star" style="position: relative;">
                            <span class="fa fa-star"  [ngClass]="{'checked' : storDetails.reviews[0].averate_rating >= 4}"></span>
                            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(storDetails.reviews[0].averate_rating > 3 && storDetails.reviews[0].averate_rating < 4)" [ngClass]="{'checked' : (storDetails.reviews[0].averate_rating > 3 && storDetails.reviews[0].averate_rating < 4) , 'fa-star-half' : (storDetails.reviews[0].averate_rating > 3 && storDetails.reviews[0].averate_rating < 4) }"></span>
                         </div>
                        <div class="main-star" style="position: relative;">
                            <span class="fa fa-star" [ngClass]="{'checked' : storDetails.reviews[0].averate_rating == 5}"></span>
                            <span class="fa fa-star fa-solid fa-star-half" *ngIf="(storDetails.reviews[0].averate_rating > 4 && storDetails.reviews[0].averate_rating < 5)" [ngClass]="{'checked' : (storDetails.reviews[0].averate_rating > 4 && storDetails.reviews[0].averate_rating < 5) , 'fa-star-half' : (storDetails.reviews[0].averate_rating > 4 && storDetails.reviews[0].averate_rating < 5) }"></span>
                        </div>
                    </div>
                    <label> <strong>{{storDetails.reviews[0].averate_rating }}</strong> Google Ratings </label>
                </div>
            </div>
        </div>
    </div>
</section>
<div *ngIf="isThankYouPage" class="thank-you-main-div mt-5">
    <div class="thankYoumsg">
        <h3>Thank You!</h3>
        <h6 class="mt-3" *ngIf="userName && userName.length <= 20">Thank you for your interest in Dell{{ userName && userName.length > 0 ? ", ": "."}} {{userName}}{{ userName && userName.length > 0 ? ".": ""}}</h6>
        <div class="px-15" *ngIf="userName.length > 20" [innerHTML]="userName"></div>
        <p class="mt-2 mb-5 h2">Our Dell representative will contact you soon.</p>
    </div>
</div>
<section class="storeDetailWrap">
    <div class="container">
        <div class="backBtnwrap" *ngIf="!isThankYouPage">
            <span class="backBtn" on-click="goBack()"> Back to Store Listing </span>
        </div>
        <div class="storeDetail">
            <app-store-gallery *ngIf="storDetails" [storDetails]="storDetails" (requestCallBackWindow)="handleOpenPopup($event)"></app-store-gallery>
             <div class="googleReview" *ngIf="storDetails && storDetails.reviews.length > 0">
                <div class="grHead">
                    <h2 class="boldLIght towLineHead">
                        <strong><span>Google</span></strong> Business Reviews
                    </h2>
                </div>

                <div class="googleReviewSlide">
                    <div class="grList" *ngIf="storDetails && storDetails.reviews.length > 0">
                        <div class="grItem" *ngFor="let review of storDetails.reviews">
                            <div class="giHead">
                                <span class="giLeft">
                                    <h3 style="margin-bottom: auto;">{{review.display_Name}}</h3>
                                    <i>Posted on : {{review.crt_Dt | date: 'dd/MM/yyyy hh:mm a'}}</i>
                                </span>
                                <span class="starRating">{{review.star_Rating}}</span>
                            </div>
                            <div class="reviws">
                                <p>{{review.comment}}</p>
                            </div>
                        </div>

                    </div>

                    <div class="swiper-scrollbar"></div>
                </div>
            </div>
            <div>
            <div class="enqueryForm" id="getInTouch" *ngIf="!isThankYouPage && !showRequestCallBack">
                <div class="eqFwrap">
                    <h2 class="boldLIght towLineHead">
                        <strong><span>Enquiry Get A call</span></strong> Please fill in your details below and our team
                        will get in touch with you.
                    </h2>
                   <form [formGroup]="enquiryForm">
                        <ul class="efListwrap">
                            <li>
                                <span>
                                    <label>Name</label>
                                    <input class="input" type="text" placeholder="Enter Name" formControlName="name" autocomplete="off"
                                        maxlength="100" />
                                </span>
                                <small class="error" *ngIf="submitted && myForm.name.errors?.required">
                                    <sup>*</sup>Enter your name
                                </small>
                                <small class="error" *ngIf="submitted && myForm.name.errors?.minlength">
                                    <sup>*</sup>Name must be 2 characters long
                                </small>
                                <small class="error" *ngIf="submitted && myForm.name.errors?.pattern">
                                    <sup>*</sup>Please enter a valid name
                                </small>
                            </li>
                            <li>
                                <span>
                                    <label>Mobile*</label>
                                    <input class="input" type="text" placeholder="+91" 
                                    (input)="maxLengthTen($event)" inputmode="numeric" autocomplete="off"
                                     maxlength="10"
                                        formControlName="mobileNo"  />
                                    <small class="error" *ngIf="submitted && myForm.mobileNo.errors?.required">
                                        <sup>*</sup>Please enter your Mobile number
                                    </small>
                                    <small class="error" *ngIf="submitted && myForm.mobileNo.errors?.minlength">
                                        <sup>*</sup>Please enter 10 digit Mobile Number
                                    </small>
                                </span>
                            </li>

                            <li>
                                <span>
                                    <label>Pincode*</label>
                                    <input class="input" type="text" placeholder="Pincode"  appOnlynumber
                                        formControlName="pincode" maxlength="6"
                                        (input)="maxLengthSix($event)" inputmode="numeric" autocomplete="off"
                                         />
                                    <small class="error" *ngIf="submitted && myForm.pincode.errors?.required">
                                        <sup>*</sup>Please enter your Pincode
                                    </small>
                                    <small class="error" *ngIf="submitted && myForm.pincode.errors?.minlength">
                                        <sup>*</sup>Please enter 6 digit Pincode
                                    </small>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <label>Email</label>
                                    <input class="input" type="text" placeholder="Enter Email" formControlName="email"
                                        maxlength="100" autocomplete="off" />
                                    <small class="error" *ngIf="submitted && myForm.email.errors?.required">
                                        <sup>*</sup>Enter your email
                                    </small>
                                    <small class="error" *ngIf="submitted && myForm.email.errors?.pattern">
                                        <sup>*</sup>Please enter valid email
                                    </small>
                                </span>
                            </li>

                            <div class="sub">
                                <a href="javascript:void" class="gradientBtn formBtn" (click)="onSubmit($event,content);app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')">{{submitText}}
                                </a>
                                <small></small>
                            </div>

                        </ul>


                    <p class="disclaimer"
                         [innerHTML]="consentText" [ngStyle]="{'font-size': '12px'}">
                    </p>
                    <div style="display: flow-root;">
                        <input type="checkbox" formControlName="consent" class="checkbox1">
                        <span></span>
                        <em style="font-size: 12px;">  <sup></sup>I would also like to recieve a text message (SMS) with the location of the nearest store.</em>
                    </div>
                    <small class="error consent-error" *ngIf="submitted && myForm.consent.errors?.required">
                        <sup>*</sup>Please provide your consent before submitting the form.
                    </small>
                </form>
                </div>
                <div class="thankYoumsg" style="display: none">
                    <h3>Thank You</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus iaculis.
                    </p>
                    <a href="#" class="crossBtn"></a>
                </div>
            </div>

            <div id="getInTouch1">
            <div class="enqueryForm" *ngIf="showRequestCallBack && !isThankYouPage">
                <div class="eqFwrap" id="getInTouch1">
                    <h2 class="boldLIght towLineHead">
                        <strong><span>Request a call back</span></strong> Please fill in your details below and our team
                        will get in touch with you.
                    </h2>
                    <form [formGroup]="requestCallBackForm">
                        <ul class="efListwrap">
                            <li>
                                <span class="inpDiv">
                                    <label>Name*</label>
                                    <input class="input" type="text" placeholder="Enter Name" formControlName="name" autocomplete="off"
                                        maxlength="100" />
                                    <small class="error" *ngIf="requestForm.name.errors?.required && requestForm.name.dirty">
                                        <sup>*</sup>Enter your name
                                    </small>
                                    <small class="error" *ngIf="requestForm.name.errors?.minlength && requestForm.name.dirty && !requestForm.name.errors?.pattern">
                                        <sup>*</sup>Name must be 2 characters long
                                    </small>
                                    <small class="error" *ngIf="requestForm.name.errors?.pattern && requestForm.name.dirty">
                                        <sup>*</sup>Please enter a valid name
                                    </small>
                                </span>
                            </li>
                            <li>
                                <span class="inpDiv">
                                    <label>Mobile*</label>
                                    <input class="input" type="text" placeholder="+91" 
                                    (input)="maxLengthTen($event)" inputmode="numeric" autocomplete="off"
                                    maxlength="10" formControlName="mobileNo"  />
                                    <small class="error" *ngIf="requestForm.mobileNo.errors?.required && requestForm.mobileNo.dirty">
                                        <sup>*</sup>Please enter your Mobile number
                                    </small>
                                    <small class="error" *ngIf="requestForm.mobileNo.errors?.minlength && requestForm.mobileNo.dirty">
                                        <sup>*</sup>Please enter 10 digit Mobile Number
                                    </small>
                                </span>
                            </li>
            
                            <li>
                                <span class="inpDiv">
                                    <label>Pincode</label>
                                    <input class="input" type="text" placeholder="Pincode"  appOnlynumber
                                        formControlName="pincode" maxlength="6"
                                        (input)="maxLengthSix($event)" inputmode="numeric" autocomplete="off"
                                        />
                                    <small class="error" *ngIf="requestForm.pincode.errors?.required && requestForm.pincode.dirty">
                                        <sup>*</sup>Please enter your Pincode
                                    </small>
                                    <small class="error" *ngIf="requestForm.pincode.errors?.minlength && requestForm.pincode.dirty">
                                        <sup>*</sup>Please enter 6 digit Pincode
                                    </small>
                                </span>
                            </li>
                            <li>
                                <span class="inpDiv">
                                    <label class="mail-align">Email*</label>
                                    <input class="input" type="text" placeholder="Enter Email" formControlName="email"
                                        maxlength="100" autocomplete="off" />
                                    <small class="error" *ngIf="requestForm.email.errors?.required && requestForm.email.dirty">
                                        <sup>*</sup>Enter your email
                                    </small>
                                    <small class="error mail-align" *ngIf="requestForm.email.errors?.pattern && requestForm.email.dirty">
                                        <sup>*</sup>Please enter valid email
                                    </small>
                                </span>
                            </li>
                        </ul>
                        <!-- class="mb-1" -->
                        <ul class="efListwrap">
                            <li>
                                <span>
                                    <mat-form-field class="mat-aligns">
                                        <label >Product of Interest</label>
                                        <mat-select formControlName="productOfInterest" class="prod-input">
                                            <mat-option *ngFor="let product of productInterest" [value]="product.name">
                                                {{ product.name }}
                                            </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <mat-form-field class="mat-aligns">
                                        <label>Date*</label>
                                        <input matInput [matDatepicker]="picker" formControlName="callDate" class="date-input" [min]="minDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                      </mat-form-field>
                                      <small class="error" *ngIf="requestForm.callDate.errors?.required && requestForm.callDate.dirty">
                                        <sup>*</sup>Please enter date
                                    </small>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <mat-form-field class="mat-aligns">
                                        <label>Time*</label>
                                        <mat-select formControlName="callTime" class="time-input">
                                            <mat-option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</mat-option>
                                            <mat-option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</mat-option>
                                            <mat-option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</mat-option>
                                            <mat-option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</mat-option>
                                            <mat-option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</mat-option>
                                            <mat-option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</mat-option>
                                            <mat-option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</mat-option>
                                            <mat-option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</mat-option>
                                            <mat-option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</mat-option>
                                            <mat-option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</mat-option>
                                            
                                        </mat-select>
                                      </mat-form-field>
                                      <small class="error" *ngIf="requestForm.callTime.errors?.required && requestForm.callTime.dirty">
                                        <sup>*</sup>Please enter a time
                                      </small>
                                </span>
                            </li>
                            <li>
                                <span class="btn-call">
                                    <!-- <div class="sub1"> -->
                                        <button style="width: 150px;height: 53px;" [disabled]="!this.requestCallBackForm.valid" class="formBtn" (click)="onRequestCallSubmit($event,content);app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')" 
                                        [ngClass]="{'btn btn-secondary': !requestCallBackForm.valid, 'yellowBtn large': requestCallBackForm.valid}">Submit
                                        </button>
                                        <!-- <small></small> -->
                                    <!-- </div> -->
                                </span>
                            </li>
                        
                        </ul>
                        <div class="submitBtncheckobx1">
                            <div [innerHTML]="consentText" [ngStyle]="{'font-size': '12px'}"></div>
                    
                            <div style="display: flow-root;">
                                <input type="checkbox" formControlName="consent" class="checkbox">
                                <span></span>
                                <em style="font-size: 12px;">  <sup></sup>I would like to recieve a text message (SMS) with the location of the nearest store.</em>
                            </div>
                        </div>

                    </form>
                </div>
             </div>
                
                <div class="thankYoumsg" style="display: none">
                    <h3>Thank You</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus iaculis.
                    </p>
                    <a href="#" class="crossBtn"></a>
                </div>
            </div>

        </div>
           
            <div class="sotreListwrap" *ngIf="storeImages && storeImages.length > 0">
                <h2 class="boldLIght towLineHead">
                    <strong><span>Store</span></strong> The best way to buy the products you love.
                </h2>
                <ul class="storeList">
                    <li *ngFor="let store of storeImages" class="li">
                        <img [src]="store.src" alt="" style="margin-bottom: 15px;"/>
                        <h2>{{ store.heading }}</h2>
                        <p>{{ store.text }}</p>
                        <span *ngIf="store.price" [innerHTML]="store.price"></span>
                        <div class="btnwrap">
                            <a
                            (click)="app.getDataLayervalues(store, 'Get_in_touch_product')"
                                [href]="getMobileNumber(storDetails,store.vfn)" class="gradientBtn">Get in
                                Touch</a>
                        </div>
                    </li>

                </ul>
            </div>
            <app-faq *ngIf="!isThankYouPage"></app-faq>
        </div>
    </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="thankYoumsg">
        <button class="btn-close thankyou-btn-close" aria-label="Close" (click)="d('Cross click')"></button>
        <h3 class="modal-title" id="modal-basic-title">Thank You</h3>

    </div>
    <div class="modal-body text-center">
        <p><strong>{{description}}</strong></p>
    </div>
</ng-template>